import DownloadIcon from '@mui/icons-material/DownloadRounded'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import ShareIcon from '@mui/icons-material/Share'
import WarningIcon from '@mui/icons-material/Warning'
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Input,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { MenuIcon } from 'genesis-suite/components'
import CopyIcon from 'genesis-suite/icons/Clipboard'
import SendIcon from 'genesis-suite/icons/Send'
import { collaborationCreators } from '../actions/creators'
import { logEvent } from '../lib/amplitudeClient'
import { moduleService } from '../lib/services'
import { combineFilters, getBaseUrl } from '../lib/utils'
import {
    applicationSelectors,
    authSelectors,
    filterSelectors,
    moduleSelectors,
    perspectiveSelectors,
    themeSelectors,
    widgetSelectors,
} from '../selectors'
import { collaborationSelectors } from '../selectors/collaboration.selectors'
import { ContentType, copyToClipboard, downloadFile } from './widgets/lib/widgetExporting'
import { ThemeMode } from '../types/ThemeTypes'

const useStyles = makeStyles(theme => ({
    tabIndicator: {
        //@ts-ignore
        backgroundColor: theme.mode === ThemeMode.LIGHT ? theme.palette.primary.main : '#FFF',
    },
}))

export default function CollaborationMessengerWeb({ disabled, iconProps, buttonStyle }) {
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)

    const themeMode = useSelector(themeSelectors.getThemeMode)

    const [openPopover, setOpenPopover] = useState(false)
    const [tabIndex, setTabIndex] = useState(0)

    const perspectiveUrl = `${getBaseUrl()}${useLocation().pathname}`
    const classes = useStyles()

    return (
        <MenuIcon
            buttonProps={{ sx: buttonStyle }}
            closeButton
            icon={<ShareIcon {...iconProps} />}
            disabled={disabled}
            onClick={() => setOpenPopover(true)}
            onClose={() => setOpenPopover(false)}
            open={openPopover}
            title="Share"
            tooltip="Share perspective"
        >
            <Box sx={{ overflow: 'hidden', width: '350px' }}>
                <CopyInput value={perspectiveUrl} />
                <Box display="flex" alignItems="center" gap={1}>
                    <WarningIcon color="warning" />
                    <Typography variant="body1" display="inline">
                        URL sharing does not currently support filters and other contextual information
                    </Typography>
                </Box>

                <Tabs
                    textColor={themeMode === ThemeMode.LIGHT ? 'primary' : 'inherit'}
                    classes={{ indicator: classes.tabIndicator }}
                    onChange={(_, index) => setTabIndex(index)}
                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                    value={tabIndex}
                >
                    <Tab label="Active Users" />
                    {isPowerUser && <Tab label="QR Code" />}
                </Tabs>

                <Box sx={{ height: '300px', overflow: 'auto', display: 'flex' }}>
                    {tabIndex === 0 && <OnlineShare />}
                    {tabIndex === 1 && <PublicShare />}
                </Box>
            </Box>
        </MenuIcon>
    )
}

function OnlineShare() {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const users = useSelector(collaborationSelectors.collaborators)
    const networkIsDown = useSelector(collaborationSelectors.isBroken)
    const dispatch = useDispatch()

    function send(id) {
        dispatch(collaborationCreators.collaborationSendPerspective(id))
        showSnackbar('Perspective shared', { variant: 'success' })
        logEvent('SEND_SHARE_PERSPECTIVE')
    }

    return (
        <List sx={{ flex: 1 }}>
            {networkIsDown ? (
                <ListItem>Collaboration Network Error. Please try again.</ListItem>
            ) : users && users.length > 0 ? (
                users.map(collaborator => {
                    return (
                        <ListItem button onClick={() => send(collaborator.id)} key={collaborator.id}>
                            <ListItemText primary={collaborator.name} />

                            <ListItemIcon sx={{ color: 'inherit' }}>
                                <SendIcon />
                            </ListItemIcon>
                        </ListItem>
                    )
                })
            ) : (
                <ListItem>No active users</ListItem>
            )}
        </List>
    )
}

function PublicShare() {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const moduleId = useSelector(moduleSelectors.getModuleId)
    const networkContext = useSelector(filterSelectors.getCoord)
    const globalAndPerspectiveFilters = useSelector(filterSelectors.currentFiltersConfiguration)
    const id = useSelector(widgetSelectors.getCurrentPerspectiveId)
    const titleWithContext = useSelector(perspectiveSelectors.getTitle)
    const appInfo = useSelector(applicationSelectors.getAppInfo)

    const [loading, setLoading] = useState(false)
    const [publicDetails, setPublicDetails] = useState(null)
    const isPublicAccessEnabled = useSelector(applicationSelectors.getPublicAccessEnabled)
    async function generate() {
        setLoading(true)
        try {
            const coordFilter = coordToFilter(networkContext)
            const otherFilters = combineFilters(networkContext?.Filters, globalAndPerspectiveFilters)
            const filters = [...(coordFilter ? [coordFilter] : []), ...(otherFilters ?? [])]

            const details = await moduleService.createPerspectiveURL(
                moduleId,
                appName,
                'perspective',
                id,
                titleWithContext,
                appInfo.appId,
                appInfo.cloudId,
                appInfo.cloudName,
                filters,
                isPublicAccessEnabled,
                window.location.href,
                'QRCode'
            )
            setPublicDetails({
                file: details.ImageToken,
                name: details.Name,
                qrCodeSrc: details.ImageUrl,
                url: details.Url,
            })
        } catch (err) {
            showSnackbar('Failed to create public URL', { variant: 'error' })
            console.error(err)
        }
        setLoading(false)
    }

    return (
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ py: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography>Generate QR code for {isPublicAccessEnabled ? 'public' : 'private'} access</Typography>
                <Tooltip
                    title={
                        isPublicAccessEnabled
                            ? `The QR code URL will allow any user to access this perspective (and
                other perspectives that the user can navigate to from this perspective) without other application
                features.`
                            : `The QR code URL will allow users who has access to this perspective to access this perspective. Users will be asked to login if they are not logged in.`
                    }
                >
                    <InfoIcon />
                </Tooltip>
            </Box>

            {loading ? (
                <CircularProgress sx={{ margin: 'auto' }} />
            ) : publicDetails ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flex: 1, overflow: 'hidden' }}>
                    <CopyInput value={publicDetails.url} />
                    <CopyImage {...publicDetails} />
                </Box>
            ) : (
                <Button
                    variant="outlined"
                    onClick={generate}
                    sx={{ color: 'text.primary', borderColor: 'text.primary' }}
                >
                    Generate
                </Button>
            )}
        </Box>
    )
}

function CopyInput({ value }) {
    const themeMode = useSelector(themeSelectors.getThemeMode)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    async function handleCopy() {
        await window.navigator.clipboard.writeText(value)
        showSnackbar('URL copied', { variant: 'success' })
    }

    return (
        <Input
            readOnly
            fullWidth
            value={value}
            sx={{ my: 1, '&::before': { borderColor: 'text.primary' } }}
            endAdornment={
                <IconButton
                    color={themeMode === ThemeMode.LIGHT ? 'primary' : 'inherit'}
                    onClick={handleCopy}
                    size="large"
                >
                    <CopyIcon />
                </IconButton>
            }
        />
    )
}

function CopyImage({ qrCodeSrc, file, name }) {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    async function handleCopy() {
        const blob = await getFileBlob()
        copyToClipboard(blob, ContentType.PNG)
        showSnackbar('Image copied', { variant: 'success' })
    }

    async function handleDownload() {
        const blob = await getFileBlob()
        downloadFile(blob, name, ContentType.PNG)
    }

    function getFileBlob() {
        try {
            return moduleService.getFiles(file)
        } catch (err) {
            console.error(err)
            showSnackbar('Could not get image', { variant: 'error' })
        }
    }

    return (
        <Box display="flex" justifyContent="center" flex={1} overflow="hidden" alignItems="center">
            <img style={{ maxHeight: '100%', maxWidth: '100%' }} src={qrCodeSrc} />
            <IconButton onClick={handleCopy}>
                <CopyIcon />
            </IconButton>
            <IconButton onClick={handleDownload}>
                <DownloadIcon />
            </IconButton>
        </Box>
    )
}

function coordToFilter(coord) {
    if (!coord?.FieldName) return

    return {
        PropertyName: coord.FieldName,
        ResourceName: coord.Name,
        ResourceType: 'Concept',
        Values: [coord.Value],
        Operator: '=',
    }
}

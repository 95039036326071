import { useState } from 'react'
import Measure from 'react-measure'
import { Box, ButtonBase, useTheme } from '@mui/material'
import tinycolor from 'tinycolor2'
import { ChopText } from 'genesis-suite/components'
import { useIsMobile } from '~/hooks/useIsMobile'

interface Props {
    items: Item[]
    /** (optional) callback on brick item click */
    onSelect: (itemId: string) => void
    /** (default 80) Height of brick */
    brickHeight?: number
    /** (default 150) Roughly width of the brick but stretches */
    brickWidth?: number
}

interface Item {
    id: string
    title: string
    /** (default primary) color of brick */
    backgroundColor?: string
    /** (optional) cypress id for testing */
    cypressId?: string
}

const spacing = 8

export default function BrickGrid({ items, onSelect, brickWidth = 150, brickHeight }: Props) {
    const [width, setWidth] = useState(0)
    const isMobile = useIsMobile()

    const topRowBrickCount = Math.floor(width / brickWidth)
    const rows = makeRows(items, topRowBrickCount)
    const correctedBrickWidth = (width - (topRowBrickCount - 1) * spacing) / (topRowBrickCount + 0.5)

    return (
        <Measure client onResize={contentRect => setWidth(contentRect.client?.width)}>
            {({ measureRef }) => (
                <div ref={measureRef}>
                    <Box display="flex" flexDirection="column" gap={`${spacing}px`}>
                        {rows?.map((row, i) => (
                            <Box
                                key={i}
                                display="flex"
                                gap={`${spacing}px`}
                                justifyContent={rows?.length === 1 ? 'center' : 'flex-start'}
                                marginLeft={isMobile ? 0 : i % 2 ? `${correctedBrickWidth / 2}px` : undefined}
                                flexDirection={isMobile ? 'column' : 'row'}
                            >
                                {row.map(item => (
                                    <Brick
                                        key={item.id}
                                        height={isMobile ? 'auto' : brickHeight}
                                        width={isMobile ? '100%' : correctedBrickWidth}
                                        {...item}
                                        onClick={() => onSelect(item.id)}
                                        isMobile={isMobile}
                                    />
                                ))}
                            </Box>
                        ))}
                    </Box>
                </div>
            )}
        </Measure>
    )
}

interface BrickProps extends Item {
    height: number | string
    onClick: () => void
    width: number | string
    isMobile: boolean
}

function Brick({ title, backgroundColor, height = 65, onClick, width, isMobile }: BrickProps) {
    const theme = useTheme()
    const color = backgroundColor ?? theme.palette.primary.main

    return (
        <ButtonBase
            data-cy={title}
            onClick={onClick}
            sx={{
                alignItems: 'center',
                backgroundColor: color,
                borderRadius: '10px',
                display: 'flex',
                height,
                justifyContent: 'center',
                overflow: 'hidden',
                p: 1,
                width,
            }}
        >
            <ChopText
                align="center"
                showEllipsis
                lineCount={3}
                sx={{ color: tinycolor(color).isLight() ? '#000' : '#fff', wordBreak: 'break-word' }}
                tooltipProps={{ placement: 'top' }}
                fontSize={isMobile ? '1rem' : 'inherit'}
            >
                {title}
            </ChopText>
        </ButtonBase>
    )
}

function makeRows(items: Item[], topRowBrickCount: number) {
    if (!items?.length || !topRowBrickCount) return

    const remainingItems = [...items]
    let rows: Item[][] = []

    for (let i = 0; i < 10000; i++) {
        const row = remainingItems.splice(0, Math.min(topRowBrickCount, remainingItems.length))
        rows = [...rows, row]
        if (!remainingItems.length) break
    }

    return rows
}

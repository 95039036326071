import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { IconButton, Drawer, Tabs, Tab } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Filter, Globe } from 'genesis-suite/icons'
import PerspectiveFilters from '../containers/PerspectiveFilters'
import GlobalFilters from '../containers/GlobalFilters'
import { widgetSelectors, filterSelectors } from '../selectors'
import { MenuIcon } from 'genesis-suite/components'

const useStyles = makeStyles(theme => ({
    button: {
        minWidth: 'unset',
        padding: 0,
    },
    drawer: {
        width: '80%',
    },
    tabItem: {
        flexGrow: 1,
    },
    filterIcon: isDisabled => ({
        fontSize: '20px',
        color: isDisabled ? theme.palette.grayscale.light : theme.typography.body.color,
    }),
}))

const MobileFiltersController = ({ disableGlobal, disablePerspective, filter }) => {
    const [open, setOpen] = useState(false)
    const isDisabled = disableGlobal && disablePerspective
    const [tabIndex, setTabIndex] = useState(filter === 'global' && !disablePerspective ? 1 : 0)
    const perspectiveFilters = useSelector(filterSelectors.getPerspectiveFiltersConfig)
    const filterNames = perspectiveFilters?.map(p => p.Name)

    const filterCount = useSelector(state => filterSelectors.getFilterSelectedValueCount(state, filterNames))
    const classes = useStyles(isDisabled)
    return (
        <>
            {filter === 'global' ? (
                <IconButton
                    classes={{ root: classes.button }}
                    disabled={isDisabled}
                    onClick={() => setOpen(true)}
                    size="large"
                >
                    <Globe className={classes.filterIcon} />
                </IconButton>
            ) : (
                <MenuIcon
                    classes={{ root: classes.button }}
                    disabled={isDisabled}
                    onClick={() => setOpen(true)}
                    badge={filterCount}
                    icon={<Filter className={classes.filterIcon} />}
                    size="large"
                />
            )}

            <Drawer classes={{ paper: classes.drawer }} anchor="right" open={open} onClose={() => setOpen(false)}>
                <Tabs value={tabIndex} onChange={(_, index) => setTabIndex(index)}>
                    <Tab classes={{ root: classes.tabItem }} disabled={disablePerspective} label="Perspective" />
                    <Tab classes={{ root: classes.tabItem }} disabled={disableGlobal} label="Global" />
                </Tabs>
                {tabIndex === 0 && <PerspectiveFilters />}
                {tabIndex === 1 && <GlobalFilters />}
            </Drawer>
        </>
    )
}

const mapStateToProps = state => {
    const globalFilters = filterSelectors.getGlobalFiltersConfig(state)
    const perspectiveId = widgetSelectors.getCurrentPerspectiveId(state)
    let perspectiveConfig, perspectiveFilters

    if (perspectiveId) perspectiveConfig = widgetSelectors.getWidgetConfig(state, perspectiveId)
    if (perspectiveConfig) perspectiveFilters = perspectiveConfig.FilterConfig

    return {
        disableGlobal: globalFilters.length === 0,
        disablePerspective: !perspectiveFilters || perspectiveFilters.length === 0,
    }
}

export default connect(mapStateToProps)(MobileFiltersController)

import { messengerType } from '../types'
import { applicationSelectors, authSelectors } from '~/selectors'
import { messageService, userService } from '~/lib/services'
import { UsersRequest } from 'genesis-suite/types/visualTypes'
import { messengerSelectors } from '~/selectors/messenger.selectors'
import { snackbarCreators } from './snackbar.creators'

interface UserList {
    id: string
    username: string
}

interface UserChat {
    senderId: string
    receiverId: string
    lastUnreadMessage: any
    date: string
}

const MAX_OPEN_CHATS = 3
const DEFAULT_PAGE_SIZE = 10

const setUserList = (userList: Array<UserList>) => {
    return { type: messengerType.UPDATE_USER_LIST, payload: userList }
}

const setUserMessages = (userChatList: Array<UserList>) => {
    return { type: messengerType.UPDATE_MESSAGES_LIST, payload: userChatList }
}

const openUserChat = (user: any) => {
    return (dispatch, getState) => {
        const allUserChats = messengerSelectors.getAllUserChats(getState())
        const chatList = messengerSelectors.getMessageList(getState())

        if (!user || allUserChats?.some(chat => chat?.userData?.id === user?.id)) return

        const updatedChats =
            allUserChats.length >= MAX_OPEN_CHATS
                ? allUserChats.slice(1) // Remove the oldest chat
                : allUserChats

        dispatch({
            type: messengerType.UPDATE_USER_CHAT_LIST,
            payload: [
                ...updatedChats,
                {
                    userData: user,
                    chatMessages: [],
                },
            ],
        })

        const newChatList = chatList.map(chat => {
            if (chat.senderId === user.id) {
                return { ...chat, lastUnreadMessage: { ...chat.lastUnreadMessage, isUnread: false }, unreadCount: 0 }
            }
            return chat
        })

        dispatch(setUserMessages(newChatList))
    }
}

const updateUserChatsList = (chatMessages: any, userId: string) => {
    return (dispatch, getState) => {
        const allUserChats = messengerSelectors.getAllUserChats(getState())
        if (!userId) return

        const updatedChats = allUserChats.map(chat => {
            if (chat?.userData?.id === userId) {
                return {
                    ...chat,
                    chatMessages: chatMessages,
                }
            }
            return chat
        })

        dispatch({
            type: messengerType.UPDATE_USER_CHAT_LIST,
            payload: updatedChats,
        })
    }
}

const pushMessageToUserChat = (message: any, userId: string) => {
    return (dispatch, getState) => {
        const allUserChats = messengerSelectors.getAllUserChats(getState())
        if (!message) return

        const updatedChats = allUserChats.map(chat => {
            if (chat?.userData?.id === userId) {
                return {
                    ...chat,
                    chatMessages: [...chat.chatMessages, message],
                }
            }
            return chat
        })

        dispatch({
            type: messengerType.UPDATE_USER_CHAT_LIST,
            payload: updatedChats,
        })
    }
}

const closeUserChat = (user: any) => {
    return (dispatch, getState) => {
        const allUserChats = messengerSelectors.getAllUserChats(getState())
        if (!user) return

        dispatch({
            type: messengerType.UPDATE_USER_CHAT_LIST,
            payload: allUserChats?.filter(chat => chat?.userData?.id !== user?.id),
        })
    }
}

const addMessageToChat = (message: any) => {
    return (dispatch, getState) => {
        const state = getState()
        const chatList = messengerSelectors.getMessageList(state)
        const allUserChats = messengerSelectors.getAllUserChats(state)
        const currentUserId = authSelectors.getUserId(state)
        const userList = messengerSelectors.getUserList(state)
        let markAsUnread = false

        //if chat box for user is not open, we need to mark it as unread message
        if (
            message.senderId !== currentUserId &&
            !allUserChats?.some(chat => chat?.userData?.id === message?.senderId)
        ) {
            markAsUnread = true
        }

        const existingChat = chatList.find(
            chat => chat.receiverId === message.receiverId && chat.senderId === message.senderId
        )
        const chatMessage = {
            senderId: message.senderId,
            receiverId: message.receiverId,
            lastUnreadMessage: { ...message, isUnread: markAsUnread },
            unreadCount: markAsUnread ? (existingChat.unreadCount || 0) + 1 : 0,
        }

        let newChatList = chatList.filter(
            chat => !(chat.receiverId === message.receiverId && chat.senderId === message.senderId)
        )
        newChatList = [chatMessage, ...newChatList]
        dispatch(messengerCreators.setUserMessages(newChatList))

        const userName = userList?.find(user => user.UserId === message.senderId)?.ScreenAlias ?? message.senderId

        if (markAsUnread) {
            // dispatch(
            //     snackbarCreators.info(`New message from ${userName}`, {
            //         anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            //     })
            // )
        }
    }
}

const toggleLoader = () => ({ type: messengerType.TOGGLE_LOADER })

const setUserChat = (userList: Array<UserList>, userChatList: Array<UserChat>) => {
    return {
        type: messengerType.UPDATE_USER_MESSAGES,
        payload: {
            messageList: userChatList,
            userList: userList,
        },
    }
}

const getUserMessages = () => {
    return (dispatch, getState) => {
        const state = getState()
        const user = authSelectors.getUser(state)
        const appName = applicationSelectors.getCurrentAppName(state)
        const appDomainId = applicationSelectors.getAuthorizedAppInfo(state, appName)?.domainId

        const { domainId, userId } = user

        if (!(domainId && appName && appDomainId)) return

        const allUserList = new Promise<Array<UserList>>(resolve => {
            const usersFetchRequest: UsersRequest = {
                ModelName: appName,
                PageSize: 10,
                PageNumber: 1,
                loaddetails: true,
                superUsersOnly: false,
            }

            userService
                .getUsersV2(usersFetchRequest)
                .then(res => resolve(res.Users?.map(user => user.UserInfo)))
                .catch(() => resolve([]))
        })

        const userChatList = new Promise<Array<UserChat>>(resolve => {
            messageService
                .getChats({ domainId: appDomainId, userId, page: 1, pageSize: 10 })
                .then(res => resolve(res))
                .catch(() => resolve([]))
        })

        dispatch(toggleLoader())
        return Promise.all([allUserList, userChatList])
            .then(([userList, userChat]) => {
                dispatch(setUserChat(userList, userChat))
            })
            .catch(error => {
                dispatch(setUserChat([], []))
            })
            .finally(() => {
                dispatch(toggleLoader())
            })
    }
}

//Todo: Add Types after finalization
export const messengerCreators = {
    toggleLoader,
    setUserList,
    openUserChat,
    closeUserChat,
    setUserMessages,
    getUserMessages,
    addMessageToChat,
    updateUserChatsList,
    pushMessageToUserChat,
}

import { useDispatch, useSelector } from 'react-redux'
import { authCreators } from '~/actions/creators'
import { applicationSelectors, authSelectors } from '~/selectors'
import { ActSnackbar } from 'genesis-suite/components'

export default function ActAsSnackbar() {
    const dispatch = useDispatch()
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const actAsUser = useSelector(authSelectors.getActAs)

    const removeUser = () => {
        const params = {
            modelName: appName,
        }
        dispatch(authCreators.deleteActAs(params))
    }

    return (
        <>
            <ActSnackbar actAsUser={actAsUser} removeUser={removeUser} />
        </>
    )
}

import React from 'react'
import { useDispatch } from 'react-redux'
import { Refresh } from 'genesis-suite/icons'
import withStyles from '@mui/styles/withStyles'
import { Typography, Button, Link } from '@mui/material'

import { appearanceCreators } from '../../actions/creators'

const styles = theme => ({
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative',
    },
    refresh: {
        fontSize: '20px',
    },
    refreshButton: {
        backgroundColor: theme.palette.grayscale.lighter,
        height: '40px',
        width: '40px',
        borderRadius: '20px',
        minWidth: 0,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    message: {
        margin: '15px 105px',
    },
    link: {
        margin: '15px 0',
        textDecorationLine: 'underline',
        color: 'blue',
        cursor: 'pointer',
    },
})

/**
 * Displays a widget error state and refresh button
 * @param {*} props.triggerRefresh - callback to refresh the widget content
 */
const WidgetError = ({ classes, triggerRefresh }) => {
    const dispatch = useDispatch()
    return (
        <div className={classes.container}>
            <Typography className={classes.message}>
                <b> Something went wrong. </b> <br /> <br />
                Please refresh the page and try again. If the error persists you can contact us <></>
                <Link className={classes.link} onClick={() => dispatch(appearanceCreators.toggleHelp())}>
                    here
                </Link>
            </Typography>
            {triggerRefresh && (
                <Button className={classes.refreshButton} onClick={triggerRefresh}>
                    <Refresh classes={{ root: classes.refresh }} />
                </Button>
            )}
        </div>
    )
}

export default withStyles(styles)(WidgetError)

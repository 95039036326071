import { Box, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FieldVisibility, FormData, FormPalette, FormSection } from 'genesis-suite/types/visualTypes'
import { ComponentType, useCallback, useMemo, useRef, useState } from 'react'
import GridLayout from 'react-grid-layout'
import Measure from 'react-measure'
import useDashboardId from '../../../hooks/useDashboardId'
import { useIsMobile } from '../../../hooks/useIsMobile'
import InputFormPrompt from './InputFormPrompt'

const useStyles = makeStyles(({ spacing }) => ({
    promptList: {
        flex: 1,
        overflow: 'auto',
        display: 'flex',
        flexFlow: 'column',
        //@ts-ignore
        '& .MuiFormControlLabel-label': { color: ({ fontColor }) => fontColor, fontSize: 14 },
        //@ts-ignore
        '& .MuiFormLabel-root': { color: ({ fontColor }) => fontColor, fontSize: 14 },
    },
    //@ts-ignore
    promptTitle: { color: ({ fontColor }) => fontColor },
}))

export interface SectionProps {
    values: FormData
    section: FormSection
    palette: FormPalette
    onSubmit: (data: FormData) => void
    onChange: (value: FormData, isAutoSaveOn?: boolean) => void
    FormActions: ComponentType<{ onSubmit: () => void; submitDisabled: boolean }>
    multiEdit?: boolean
    isEditingLayout?: boolean
    onLayoutChange?: (section: FormSection) => void
    FormFooter?: ComponentType<{ row: any }>
    readOnly?: boolean
}

export default function InputFormSection({
    values,
    section,
    palette,
    onSubmit,
    onChange,
    FormActions,
    multiEdit,
    isEditingLayout,
    onLayoutChange,
    FormFooter,
    readOnly = false,
}: SectionProps) {
    const isMobile = useIsMobile()
    const { isV2 } = useDashboardId()
    const [width, setWidth] = useState(1000)
    const [textAreaHeight, setTextAreaHeight] = useState(0)
    const [hasError, setHasError] = useState(false)
    const theme = useTheme()
    const parentRef = useRef<HTMLDivElement>()

    const { background, fontColor } = palette
    const classes = useStyles({ background, fontColor })

    const handleChange = ({ property = null, id }, val, isAutoSaveOn = false) => {
        if (property) onChange({ [property.name]: val }, isAutoSaveOn)
        else onChange({ [id]: val }, isAutoSaveOn)
    }

    const { prompts, layout } = section

    const handleError = (reason, value) => {
        if (reason) setHasError(true)
        else setHasError(false)
    }

    // One memoized reduce for all prompts
    const { hasEditable, requiredKeys, visiblePrompts } = useMemo(() => {
        return prompts.reduce(
            (acc, p) => {
                if (!acc.hasEditable && p.editable) acc.hasEditable = true
                if (p.required && !(p.autogenerate || p.autogeneratePrefix || p.lastUpdateField))
                    acc.requiredKeys.push(p.property?.name || p.id)
                if (p.visibility === FieldVisibility.SHOW || p.visibility === FieldVisibility.FORM)
                    acc.visiblePrompts.push(p)
                return acc
            },
            { hasEditable: false, requiredKeys: [], visiblePrompts: [] }
        )
    }, [prompts])

    const isValid =
        !hasError &&
        requiredKeys.every(key => {
            const value = values[key]
            if (Array.isArray(value)) {
                return value.length
            } else return values[key] != null || values[key]
        })

    const flexLayout = !isV2
    const promptProps = {
        values,
        prompts,
        multiEdit,
        flexLayout,
        palette,
        isEditingLayout,
        onError: handleError,
        onChange: handleChange,
        textAreaHeight,
    }

    const calculateTextAreaHeight = ref => {
        const { clientHeight, children } = ref.current || {}

        const totalTextAreas = visiblePrompts?.filter(
            p => p.property.semanticType.id === 20 || p.property.semanticType.id === 21
        )?.length

        //Calculating the height of all the input fields other than TextArea
        const otherFieldsHeight = [...children]
            ?.filter(
                (_, index) =>
                    visiblePrompts[index]?.property?.semanticType?.id !== 20 &&
                    visiblePrompts[index]?.property?.semanticType?.id !== 21
            )
            ?.map(child => child.clientHeight)
            ?.reduce((acc, height) => acc + height, 0)

        setTextAreaHeight((clientHeight - otherFieldsHeight) / (totalTextAreas === 0 ? 1 : totalTextAreas))
    }

    const handleResize = useCallback(() => {
        calculateTextAreaHeight(parentRef)
    }, [])

    return (
        <>
            {flexLayout ? (
                <Measure offset client innerRef={parentRef} onResize={handleResize}>
                    {({ measureRef }) => (
                        <Box ref={measureRef} className={classes.promptList}>
                            {visiblePrompts.map(p => (
                                <InputFormPrompt key={p.id} prompt={p} {...promptProps} readOnly={readOnly} />
                            ))}
                            {FormFooter && <FormFooter row={values} />}
                        </Box>
                    )}
                </Measure>
            ) : (
                <Measure
                    offset
                    client
                    onResize={({ client }) => {
                        setWidth(client.width - 2)
                    }}
                >
                    {({ measureRef }) => (
                        <div ref={measureRef} className={classes.promptList}>
                            <GridLayout
                                width={width}
                                rowHeight={60}
                                layout={layout}
                                autoSize={true}
                                resizeHandles={['ne']}
                                cols={isMobile ? 1 : 3}
                                compactType={'vertical'}
                                isDraggable={Boolean(isEditingLayout)}
                                isResizable={Boolean(isEditingLayout)}
                                onLayoutChange={layout => onLayoutChange({ ...section, layout })}
                            >
                                {visiblePrompts.map(p => {
                                    return (
                                        <Box
                                            key={p.id}
                                            sx={
                                                isEditingLayout
                                                    ? {
                                                          px: 1,
                                                          '&:hover': {
                                                              border: 1,
                                                              cursor: 'grab',
                                                              borderRadius: 4,
                                                              borderColor: 'grayscale.dark',
                                                          },
                                                          '& .react-resizable-handle.react-resizable-handle-ne': {
                                                              top: '2px !important',
                                                              right: '2px !important',
                                                          },
                                                      }
                                                    : {}
                                            }
                                            data-grid={{ x: 1, y: 1, w: 3, h: 1, maxH: 1 }}
                                        >
                                            <InputFormPrompt prompt={p} {...promptProps} readOnly={readOnly} />
                                        </Box>
                                    )
                                })}
                            </GridLayout>
                            {FormFooter && <FormFooter row={values} />}
                        </div>
                    )}
                </Measure>
            )}

            <Box justifyContent="flex-end" paddingTop={theme.spacing(1)}>
                {hasEditable && (
                    <FormActions
                        onSubmit={() => onSubmit(values)}
                        submitDisabled={!isValid || isEditingLayout || readOnly}
                    />
                )}
            </Box>
        </>
    )
}

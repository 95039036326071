import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { applicationSelectors, authSelectors } from '~/selectors'
import { messengerSelectors } from '~/selectors/messenger.selectors'
import { messengerCreators } from '~/actions/creators/messenger.creators'

import ChatBox from './ChatBox'
import NewChatBox from './NewChat'
import MessagesBox from './MessagesBox'

const useStyles = makeStyles(({ palette }) => ({
    chatBarContainer: {
        position: 'fixed',
        bottom: 0,
        right: '100px',
        display: 'flex',
        flexDirection: 'row-reverse',
        gap: '20px',
        zIndex: 1000,
    },

    chatBoxContainer: {
        transform: 'translateY(100%)',
        transition: 'transform 0.5s',
        boxShadow: '6px -8px 5px -4px #888',
    },

    chatBoxToggle: {
        position: 'absolute',
        top: '-44px',
        right: 0,
        width: '300px',
        height: '45px',
        borderRadius: '6px 6px 0px 0px',
        backgroundColor: palette.background.sideNav,
        color: palette.text.primary,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px solid',
        borderColor: palette.border.main,
        padding: '0 5px',
        boxShadow: '6px 0 5px -4px #888',
        cursor: 'pointer',
    },

    chatHeader: {
        cursor: 'pointer',
    },
    chatBox: {
        width: '300px',
        height: '0px',
        border: '1px solid',
        borderColor: palette.border.main,
        padding: '8px 0 8px 8px',
        backgroundColor: palette.background.default,
        color: palette.text.primary,
    },

    chatBoxOpen: {
        height: '400px',
        transform: 'translateY(0)',
    },

    avatar: {
        width: 28,
        height: 28,
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        fontSize: 20,
        '&:hover': {
            cursor: 'pointer',
            opacity: 1,
        },
    },
    buttonStyle: {
        padding: 0.25,
        color: palette.text.primary,
    },
    searchBox: {
        paddingRight: '8px',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.border.main,
            opacity: 0.6,
        },
        '&:hover .MuiOutlinedInput-notchedOutline,': {
            borderColor: palette.border.main,
            opacity: 1,
        },
        '& .MuiOutlinedInput-input::placeholder': {
            color: palette.border.main,
            opacity: 1,
        },
        '& .MuiOutlinedInput-input': {
            color: palette.text.primary,
        },
        '& .MuiInputAdornment-root': {
            color: palette.text.primary,
        },
    },
}))

const MessageBar = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const domainId = useSelector(authSelectors.getDomainId)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const appDomainId = useSelector(state => {
        const authAppInfo = applicationSelectors.getAuthorizedAppInfo(state, appName)
        return authAppInfo ? authAppInfo.domainId : undefined
    })
    const chatList = useSelector(messengerSelectors.getAllUserChats)
    const [isNewChatOpen, setIsNewChatOpen] = useState(false)

    const toggleNewChat = () => setIsNewChatOpen(prev => !prev)

    const handleNewChatOpen = user => {
        toggleNewChat()
        handleChatOpen(user)
    }
    //close all open chats on switching apps/portals
    const handleChatOpen = user => {
        dispatch(messengerCreators.openUserChat(user))
    }

    const handleChatClose = user => {
        dispatch(messengerCreators.closeUserChat(user))
    }

    // this domain id should be app domain id. If app has no domain id then no messenger
    if (!domainId || !appDomainId) return null

    return (
        <Box className={classes.chatBarContainer}>
            <MessagesBox classes={classes} onNewChat={toggleNewChat} onChatOpen={handleChatOpen} />
            {isNewChatOpen && <NewChatBox classes={classes} onClose={toggleNewChat} onChatOpen={handleNewChatOpen} />}
            {chatList?.map(chat => (
                <ChatBox
                    key={chat?.userData?.id}
                    userData={chat?.userData}
                    classes={classes}
                    onClose={handleChatClose}
                />
            ))}
        </Box>
    )
}

export default MessageBar

import { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Box, IconButton } from '@mui/material'
import { ICellEditorParams } from '@ag-grid-community/core'
import { useSelector } from 'react-redux'
import { themeSelectors } from '~/selectors'
import { ThemeMode } from '~/types/ThemeTypes'
import tinycolor from 'tinycolor2'
import { Property } from 'genesis-suite/types/networkTypes'
import { Close } from 'genesis-suite/icons'
import makeStyles from '@mui/styles/makeStyles'
import RichTextEditorComponent from '~/components/RichTextEditorComponent'

interface FormTableTextEditorCellProps {
    formattedVal: string
    params: ICellEditorParams
    displayProperty?: Property
    isEditing?: boolean
    disabled?: boolean
}
const useStyles = makeStyles(({ palette, spacing }) => ({
    backdrop: {
        cursor: 'not-allowed',
    },
    icon: { color: palette.text.primary },
}))
export default function FormTableTextEditorCell({
    formattedVal,
    params,
    displayProperty,
    isEditing,
    disabled,
}: FormTableTextEditorCellProps) {
    const classes = useStyles({})

    const [open, setOpen] = useState(false)
    const themeMode = useSelector(themeSelectors.getThemeMode)
    const [inputVal, setInputVal] = useState(formattedVal)
    const hyperlinkColor =
        themeMode === ThemeMode.DARK
            ? tinycolor('#0000FF')
                  .lighten(isEditing ? 10 : 45)
                  .toString()
            : '#0000FF'
    const { api, colDef } = params
    const handleClick = () => {
        setOpen(true)
        setInputVal(formattedVal)
    }

    const handleClose = () => {
        setOpen(false)
    }
    const handleDialogClose = (event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleClose()
        }
    }

    const handleSave = () => {
        if (inputVal === formattedVal) return
        handleChange(inputVal)
        setOpen(false)
    }
    const handleChange = async value => {
        const rowNode = params.node
        const column = params.column

        if (displayProperty) {
            const updatedData = { ...rowNode.data, [displayProperty.name]: value?.label }
            rowNode.setData(updatedData)
        }
        if (typeof value === 'object') {
            rowNode.setDataValue(column, value?.value ?? null)
        } else {
            rowNode.setDataValue(column, value ?? null)
        }

        api.stopEditing()
    }

    return (
        <>
            <a
                href="#"
                onClick={handleClick}
                style={{ color: hyperlinkColor ?? 'inherit', textDecoration: 'underline' }}
            >
                Click to edit
            </a>
            <Dialog
                open={open}
                className={classes.backdrop}
                onClose={handleDialogClose}
                fullWidth={true}
                maxWidth={'md'}
                hideBackdrop
                PaperProps={{
                    sx: { cursor: 'default', backgroundImage: 'none' },
                }}
            >
                <DialogTitle>
                    {' '}
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                            <Typography variant="h5">{colDef?.headerName}</Typography>
                        </Box>
                        <IconButton onClick={handleClose} size="large">
                            <Close className={classes.icon} />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <RichTextEditorComponent
                        editable={disabled}
                        value={inputVal ?? ''}
                        onChange={(value, isAutoSaveOn) => {
                            if (params?.node?.data) {
                                params.node.data.isAutoSave = isAutoSaveOn
                            }
                            setInputVal(value)
                        }}
                        enableAutoSave={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSave}
                        color="primary"
                        disabled={inputVal === formattedVal}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

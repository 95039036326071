import RemoveIcon from '@mui/icons-material/Close'
import CollapseIcon from '@mui/icons-material/ExpandLess'
import { Box, Button, IconButton, Popover, TextField, Tooltip, Typography, TypographyProps } from '@mui/material'
import produce from 'immer'
import { isEmpty, isEqual } from 'lodash'
import { Fragment, forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CheckRounded, CloseRounded, FilterList, Search } from '@mui/icons-material'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import { SwalContext } from 'genesis-suite/components'
import { useHover } from 'genesis-suite/hooks'
import { Filter, Globe, InlineFilter } from 'genesis-suite/icons'
import FilterIcon from 'genesis-suite/icons/Filter'
import { PropertyMetaWithSemantic } from 'genesis-suite/types/networkTypes'
import { useSnackbar } from 'notistack'
import { authCreators, filterCreators, widgetCreators } from '~/actions/creators'
import { getFontSize, widgetConstants } from '~/constants'
import useProperties, { getPropertyMeta } from '~/hooks/useProperties'
import { useBrowserPreferences } from '~/lib/browserPreferences'
import { makeTemporalFilterDisplayText } from '~/lib/utils'
import { authSelectors, filterSelectors, widgetSelectors } from '~/selectors'
import { networkFilterActions, networkFilterSelectors } from '../redux/networkFilterSlice'
import { NetFiltersContext } from './network-filters/NetworkFiltersContext'
import useNetworkFilters from './network-filters/useNetworkFilters'
import { useIsMobile } from '~/hooks/useIsMobile'

const { DRILLDOWN, INFINITE } = widgetConstants.Interactions

export default function FilterString({ typesOfFiltersToDisplay = ['all'] }) {
    const { enqueueSnackbar: showSnackbar, closeSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const [expand, setExpand] = useBrowserPreferences('expandFilterString')
    const isMobile = useIsMobile()
    const [showCollapse, setShowCollapse] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)
    const { lineHeight, fontSize } = useUserFont()
    const { global, other } = useDisplayFilters(typesOfFiltersToDisplay)
    const showLock = other.length > 0
    const displayFilters = [...global, ...other]
    const interactionType = useSelector(widgetSelectors.getInteractionType)
    const [anchorEl, setAnchorEl] = useState(null)
    const [filterName, setFilterName] = useState('')
    const appliedFilters = useSaveFilters()
    const [filterNameInputErrored, setFilterNameInputErrored] = useState(false)
    const { confirm } = useContext(SwalContext)
    const inlineFilters = useSelector(filterSelectors.getInlineFilters)?.filters ?? []
    const savePreferences = prefs => dispatch(authCreators.saveUserPreferencesForCurrentApplication(prefs))
    const savedFilters = useSelector(state => authSelectors.getPreference(state, 'savedFilters'))

    useEffect(() => {
        const containerHeight = containerRef.current?.offsetHeight
        if (!containerHeight) return

        setShowCollapse(containerHeight > lineHeight + 5)
    }, [displayFilters, lineHeight])

    const onLockToggle = () => {
        const next = interactionType === DRILLDOWN ? { value: INFINITE } : { value: DRILLDOWN }
        dispatch(widgetCreators.changeInteractionType(next.value))
    }

    const onClearFilters = () => {
        dispatch(filterCreators.resetInlineFilters())
        dispatch(filterCreators.applySearchFilters([]))
        dispatch(filterCreators.applyGlobalFilters({}))
        dispatch(networkFilterActions.apply([]))
        dispatch(filterCreators.clearAllPerspectiveFilters())
    }

    const onSaveFilters = e => {
        setAnchorEl(e.target)
    }

    const handleClose = () => {
        setFilterName('')
        setAnchorEl(null)
    }

    const onFilterNameChange = e => {
        setFilterName(e.target.value)
        const existingFilterNames = Object.keys(savedFilters ?? {})

        const isPresent = existingFilterNames.some(item => item.toLowerCase() === e.target.value.toLowerCase())

        if (isPresent) {
            setFilterNameInputErrored(true)
            return
        }

        if (filterNameInputErrored) setFilterNameInputErrored(false)
    }

    const handleSaveFilters = async () => {
        if (inlineFilters?.length > 0) {
            const response = await confirm(
                'There are few inline filters which will not be saved. You want to continue?',
                {
                    type: 'warning',
                }
            )

            if (response.dismiss) return
        }

        const key = showSnackbar('Saving the filters. Please wait...', { persist: true })

        const newPreference = {
            Id: null,
            Key: 'savedFilters',
            Value: JSON.stringify({ ...(savedFilters || {}), [filterName]: appliedFilters }),
        }

        savePreferences([newPreference])
            //@ts-ignore
            .then(() => {
                showSnackbar(`Filters with name ${filterName} saved successfully`, { variant: 'success' })
            })
            .catch(error => {
                console.error(error)
                showSnackbar('Unable to save filters. Try again', { variant: 'error' })
            })
            .finally(() => {
                closeSnackbar(key)
            })

        handleClose()
    }

    if (!displayFilters.length) return null

    return (
        <>
            <Box
                sx={{
                    alignItems: 'flex-start',
                    display: 'flex',
                    fontSize,
                    gap: 1,
                    height: showCollapse && !expand ? lineHeight : undefined,
                    mt: 1,
                    overflow: 'hidden',
                }}
                id="filter-string"
                data-html2canvas-ignore
            >
                <FilterIcon fontSize="medium" />

                <Box
                    ref={containerRef}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                        flexWrap: 'wrap',
                        overflow: 'hidden',
                        overflowX: isMobile ? 'scroll' : 'hidden',
                        scrollbarWidth: isMobile ? 'thin' : 'none',
                    }}
                >
                    {displayFilters.map((f, i) => (
                        <Fragment key={f.filterName}>
                            <FilterDisplay {...f} />
                            {i !== displayFilters.length - 1 && <Typography>&</Typography>}
                        </Fragment>
                    ))}
                </Box>

                {!isMobile && (
                    <>
                        {showLock && (
                            <Tooltip title={interactionType === DRILLDOWN ? 'Unlock filters' : 'Lock Filters'}>
                                <IconButton onClick={onLockToggle} sx={{ color: 'text.primary', p: 0.25 }}>
                                    {interactionType === DRILLDOWN ? (
                                        <LockRoundedIcon fontSize="small" />
                                    ) : (
                                        <LockOpenRoundedIcon fontSize="small" />
                                    )}
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title={'Save filters with a name'}>
                            <IconButton onClick={onSaveFilters} sx={{ color: 'text.primary', p: 0, pt: 0.125 }}>
                                <SaveRoundedIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
                <Tooltip title={'Clear all filters'}>
                    <IconButton onClick={onClearFilters} sx={{ color: 'text.primary', p: 0, pt: 0.125 }}>
                        <ClearRoundedIcon fontSize="medium" />
                    </IconButton>
                </Tooltip>

                {showCollapse && (
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            endIcon={
                                <CollapseIcon
                                    fontSize="small"
                                    sx={{ transition: 'all 200ms ease' }}
                                    style={{ transform: `rotateX(${expand ? '0' : '180'}deg)` }}
                                />
                            }
                            onClick={() => setExpand(s => !s)}
                            size="small"
                            sx={{ fontSize, height: lineHeight, p: 0 }}
                            variant="outlined"
                        >
                            {expand ? 'Less' : 'More'}
                        </Button>
                    </Box>
                )}
            </Box>
            <Popover
                PaperProps={{ sx: { p: 1, display: 'flex', alignItems: 'center' } }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
            >
                <TextField
                    error={filterNameInputErrored}
                    autoFocus
                    size="small"
                    value={filterName}
                    variant="outlined"
                    placeholder="Enter Name for filters"
                    sx={{
                        display: 'inline',
                        width: 200,
                        '& .MuiFormHelperText-root': {
                            fontSize: '0.85rem',
                        },
                    }}
                    onChange={e => onFilterNameChange(e)}
                    onKeyDown={e => {
                        e.stopPropagation()
                        e.key === 'Enter' && handleSaveFilters()
                    }}
                    helperText={filterNameInputErrored ? 'This name is already used' : null}
                />
                <IconButton
                    disabled={!filterName || filterNameInputErrored}
                    size="small"
                    sx={{ color: 'text.primary' }}
                    onClick={handleSaveFilters}
                >
                    <CheckRounded fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={handleClose} sx={{ color: 'text.primary' }}>
                    <CloseRounded fontSize="small" />
                </IconButton>
            </Popover>
        </>
    )
}

const defaultTextStyle = { flexShrink: 0, fontSize: 'inherit' }

function FilterDisplay({ onRemove, operator, title, values, type }: DisplayFilter) {
    const ref = useRef()
    const isHovering = useHover(ref)
    const defaultNetworkFilter = useSelector(authSelectors.getAppDefaultNetworkFilters)

    const valuesAdj = [...values]
    const isPinned = valuesAdj?.[0]?.id ? valuesAdj[0].id === defaultNetworkFilter : false
    let truncatedValues: DisplayFilter['values'] = []
    const truncate = values.length > 3
    if (truncate) truncatedValues = valuesAdj.splice(5)

    return (
        <Box
            ref={ref}
            style={isHovering ? undefined : { borderColor: 'transparent' }}
            sx={{
                alignItems: 'center',
                border: 1,
                borderColor: 'primary.main',
                borderRadius: '5px',
                display: 'flex',
                flexShrink: 0,
                gap: 0.25,
                position: 'relative',
            }}
        >
            <Label sx={{ fontWeight: 'bold' }}>(</Label>
            {type && <FilterDisplayIcon type={type} />}
            {title && <Label sx={{ fontWeight: 'bold' }}>{title}</Label>}

            {operator && <Label sx={{ fontStyle: 'italic', px: 0.25 }}>{operatorDisplay(operator)}</Label>}

            {valuesAdj.map((value, i) => (
                <Fragment key={value.value}>
                    <Chip isPinned={isPinned}>{value.label ?? value.value}</Chip>
                    {(i < valuesAdj.length - 1 || truncate) && <Label>|</Label>}
                    {truncate && i === valuesAdj.length - 1 && (
                        <Tooltip
                            title={
                                <Box>
                                    {truncatedValues.map(v => (
                                        <Label key={v.value}>{v.label ?? v.value}</Label>
                                    ))}
                                </Box>
                            }
                        >
                            <Chip>+{values.length - 5}</Chip>
                        </Tooltip>
                    )}
                </Fragment>
            ))}
            <Label sx={{ fontWeight: 'bold' }}>)</Label>

            {isHovering && onRemove && !isPinned && (
                <IconButton
                    onClick={onRemove}
                    sx={{
                        background: 'red',
                        borderRadius: 1,
                        height: '100%',
                        p: 0,
                        position: 'absolute',
                        left: 0,
                        '&:hover': { background: 'red' },
                    }}
                >
                    <RemoveIcon sx={{ height: '100%', width: 'auto' }} />
                </IconButton>
            )}
        </Box>
    )
}

interface ChipProps extends TypographyProps {
    isPinned?: boolean
}

const Chip = forwardRef<HTMLParagraphElement, ChipProps>((props, ref) => {
    const { isPinned, ...rest } = props
    return (
        <Typography
            ref={ref}
            sx={({ palette }) => ({
                ...defaultTextStyle,
                background: isPinned ? palette.secondary.light : palette.primary.light,
                borderRadius: 1,
                color: palette.primary.contrastText,
                px: 0.5,
            })}
            {...rest}
        />
    )
})

const Label = forwardRef<HTMLParagraphElement, TypographyProps>(({ sx, ...rest }, ref) => (
    <Typography ref={ref} sx={{ ...sx, ...defaultTextStyle }} {...rest} />
))

function useSaveFilters() {
    const global = useSelector(filterSelectors.getAppliedGlobalFilters) ?? {}
    const perspective = useSelector(filterSelectors.getAppliedPerspectiveFilters) ?? {}
    const search = useSelector(filterSelectors.getAppliedSearchFilters) ?? []
    const network = useSelector(networkFilterSelectors.getAppliedIds) ?? []

    return { global, perspective, search, network }
}

interface DisplayFilter {
    filterName: string
    onRemove?: () => void
    operator?: string
    property?: PropertyMetaWithSemantic
    title?: string
    values: { value: string; label?: string; id?: string }[]
    type?: string
}

function useDisplayFilters(typesOfFiltersToDisplay) {
    const global =
        typesOfFiltersToDisplay.includes('all') || typesOfFiltersToDisplay.includes('global')
            ? useGlobalDisplayFilters()
            : []
    const perspective =
        typesOfFiltersToDisplay.includes('all') || typesOfFiltersToDisplay.includes('perspective')
            ? usePerspectiveDisplayFilters()
            : []
    const inline =
        typesOfFiltersToDisplay.includes('all') || typesOfFiltersToDisplay.includes('inline')
            ? useInlineDisplayFilters()
            : []
    const network =
        typesOfFiltersToDisplay.includes('all') || typesOfFiltersToDisplay.includes('network')
            ? useNetworkDisplayFilters()
            : []
    const search =
        typesOfFiltersToDisplay.includes('all') || typesOfFiltersToDisplay.includes('search')
            ? useSearchDisplayFilters()
            : []

    return {
        global: global,
        other: [...network, ...perspective, ...inline, ...search],
    }
}

function useGlobalDisplayFilters(): DisplayFilter[] {
    const properties = useProperties()
    const filterConfigs = useSelector(filterSelectors.getGlobalFiltersConfig)
    const filterByName = useSelector(filterSelectors.getAppliedGlobalFilters)
    const dispatch = useDispatch()

    const handleRemoveName = useCallback(
        name => {
            const { [name]: _, ...rest } = filterByName
            dispatch(filterCreators.applyGlobalFilters(rest))
        },
        [filterByName]
    )

    const filters = useMemo(
        () => createGlobalPerspectiveFilter(properties, filterByName, filterConfigs, handleRemoveName, 'global'),
        [filterConfigs, filterByName]
    )

    return filters
}

function usePerspectiveDisplayFilters() {
    const properties = useProperties()
    const filterConfigs = useSelector(filterSelectors.getPerspectiveFiltersConfig)
    const filterByName = useSelector(filterSelectors.getAppliedPerspectiveFilters)
    const dispatch = useDispatch()

    function handleClear(name) {
        const { [name]: _, ...rest } = filterByName
        dispatch(filterCreators.applyPerspectiveFilters({ ...rest, [name]: emptyFilter }))
    }

    return createGlobalPerspectiveFilter(properties, filterByName, filterConfigs, handleClear, 'perspective')
}

function createGlobalPerspectiveFilter(
    properties: PropertyMetaWithSemantic[],
    filterByName,
    filterConfigs,
    onClear,
    filterType
) {
    return Object.keys(filterByName || {})
        .map<DisplayFilter>(filterName => {
            let config = filterConfigs.find(f => f.Name === filterName)
            if (!config) config = filterByName[filterName]?.filterConfig
            if (!config) return

            const obj = filterByName[filterName]
            if (!obj.values?.length && isEmpty(obj.range) && isEmpty(obj.rangeOffset) && isEmpty(obj.clickRangeName))
                return

            const displayFilter = makeDisplayFilter(properties, config, obj)
            if (!displayFilter) return

            return { ...displayFilter, onRemove: () => onClear(filterName), type: filterType }
        })
        .filter(f => Boolean(f))
}

function makeDisplayFilter(properties: PropertyMetaWithSemantic[], config, filterValueObject): DisplayFilter {
    const property = getPropertyMeta(properties, {
        resourceName: config.Source.ElementName,
        name: config.PropertyName,
    })
    const base: Omit<DisplayFilter, 'values'> = { filterName: config.Name, property, title: config.Title }
    const { values, range, rangeOffset, clickRangeName, operator } = filterValueObject

    if (!isEmpty(values)) {
        return { ...base, operator, values }
    }
    if (clickRangeName) {
        return { ...base, values: [{ value: clickRangeName }] }
    }
    if (!isEmpty(range) || rangeOffset?.min != null || rangeOffset?.max != null) {
        return {
            ...base,
            values: [{ value: makeTemporalFilterDisplayText(filterValueObject, 'days', 'days') }],
        }
    }
}

function useInlineDisplayFilters() {
    const properties = useProperties()
    const filters: any[] = useSelector(filterSelectors.getInlineFilters)?.filters ?? []
    const dispatch = useDispatch()
    const handleRemove = useCallback(() => dispatch(filterCreators.resetInlineFilters()), [])
    if (!properties) return []

    return filters
        .reduce<DisplayFilter[]>((acc, cur) => {
            const { DisplayValues, ResourceName, PropertyName, Values } = cur
            const property = getPropertyMeta(properties, { resourceName: ResourceName, name: PropertyName })
            const index = acc.findIndex(f => isEqual(property, f.property))
            const values = (Values as any[]).map<{ value: string; label: string }>((value, i) => ({
                value,
                label: DisplayValues?.[i] || value,
            }))

            return produce(acc, draft => {
                if (index > -1) {
                    draft[index].values = [...draft[index].values, ...values]
                } else {
                    draft.push({
                        operator: 'EqualTo',
                        filterName: 'inline',
                        onRemove: handleRemove,
                        property,
                        title: property ? property.displayName : PropertyName,
                        values,
                        type: 'inline',
                    })
                }
            })
        }, [])
        .filter(f => Boolean(f))
}

function useNetworkDisplayFilters() {
    const { networkFilters: perspectiveNetworkFilters } = useContext(NetFiltersContext)
    const [globalNetworkFilters] = useNetworkFilters()
    const appliedIds: string[] = useSelector(networkFilterSelectors.getAppliedIds)
    const dispatch = useDispatch()

    const filters = useMemo(() => {
        let foundNetworkFilters = perspectiveNetworkFilters?.filter(item => appliedIds.includes(item.id)) ?? []
        if (isEmpty(foundNetworkFilters)) {
            foundNetworkFilters = globalNetworkFilters?.filter(item => appliedIds.includes(item.id)) ?? []
        }

        return foundNetworkFilters
    }, [[perspectiveNetworkFilters, globalNetworkFilters, appliedIds]])

    const handleRemoveId = useCallback(
        id => {
            const updated = appliedIds.filter(_id => _id !== id)
            dispatch(networkFilterActions.apply(updated))
        },
        [appliedIds]
    )

    return filters.map<DisplayFilter>(f => ({
        filterName: f.name,
        onRemove: () => handleRemoveId(f.id),
        values: [{ value: f.name, id: f.id }],
        type: 'network',
    }))
}

function useSearchDisplayFilters() {
    const properties = useProperties()
    const filters = useSelector(filterSelectors.getAppliedSearchFilters)
    const dispatch = useDispatch()

    if (!properties) return []

    return filters.map((f, i): DisplayFilter => {
        const property = getPropertyMeta(properties, { resourceName: f.ResourceName, name: f.PropertyName })
        const values = (f.Values as any[]).map<{ value: string; label: string }>((value, i) => ({
            value,
            label: f.DisplayValues?.[i] || value,
        }))

        return {
            operator: 'EqualTo',
            onRemove: () => dispatch(filterCreators.applySearchFilters(filters.filter((_, index) => index !== i))),
            filterName: f.FilterName,
            property,
            title: property.displayName,
            values,
            type: 'search',
        }
    })
}

function useUserFont() {
    const fontSize = getFontSize()
    const lineHeight = useMemo(() => fontSize + 7, [fontSize])
    const adjFontSize = useMemo(() => fontSize + 2, [fontSize])
    return { lineHeight, fontSize: adjFontSize }
}

function operatorDisplay(operator) {
    switch (operator) {
        case 'GreaterThan':
            return '>'
        case 'LessThan':
            return '<'
        case 'EqualTo':
            return 'equals'
        case 'NotEqualTo':
            return 'not equal to'
        case 'LessThanorEqualTo':
            return '<='
        case 'GreaterThanorEqualTo':
            return '>='
        case 'Contains':
            return 'contains'
        case 'StartsWith':
            return 'starts with'
        case 'EndsWith':
            return 'ends with'
    }
}

const FilterDisplayIcon = ({ type }) => {
    const _getIconByType = () => {
        switch (type) {
            case 'global':
                return <Globe fontSize="small" />
            case 'inline':
                return <InlineFilter fontSize="small" />
            case 'perspective':
                return <Filter fontSize="small" />
            case 'search':
                return <Search fontSize="small" />
            case 'network':
                return <FilterList fontSize="small" />
            default:
                break
        }
    }
    return <IconButton sx={{ p: 0, color: 'text.primary' }}>{_getIconByType()}</IconButton>
}

const emptyFilter = {
    Values: [],
    Range: {},
    RangeOffset: {},
    useLastRefreshDate: false,
    clickRangeName: null,
    operator: 'EqualTo',
}

import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Avatar,
    Typography,
    List,
    ListItem,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ListItemIcon,
    ListItemText,
    Divider,
    ListItemButton,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { authSelectors, applicationSelectors, moduleSelectors, menuSelectors } from '../selectors'
import { authCreators, navigationCreators } from '../actions/creators'
import { openInNewTab } from '../lib/utils'
import { routePaths } from '../lib/routes'
import { House } from 'genesis-suite/icons'
import { AddRounded, RemoveRounded } from '@mui/icons-material'

const useStyles = makeStyles(({ palette, spacing }) => ({
    container: {
        height: '100%',
        color: '#000000',
        display: 'flex',
        flexDirection: 'column',
        padding: spacing(1, 1, 0),
    },
    content: { flex: 1 },
    nameWrapper: { display: 'flex', alignItems: 'center' },
    avatar: { backgroundColor: palette.primary.main, color: palette.primary.contrastText },
    name: { marginLeft: spacing(), lineHeight: 1.4, color: palette.text.primary },
    label: { color: palette.text.primary, margin: spacing(2, 0, 1) },
    itemText: { color: palette.text.primary },
    signout: { color: palette.text.primary, margin: spacing(1, 0) },
}))

export default function MenuView({ onClose = () => {} }) {
    const currentApplication = useSelector(applicationSelectors.getCurrentAppName)
    const applications = useSelector(applicationSelectors.getAllApplications)
    const appOptions = JSON.parse(useSelector(moduleSelectors.getAppOptions) || null)
    const getHiddenBizElements = appOptions?.hideBusinessElements ?? []
    const getHidden360Views = appOptions?.hide360Views ?? []
    const modules = useSelector(moduleSelectors.getActiveModules)
    const moduleTitle = useSelector(moduleSelectors.getModuleTitle)
    const userAlias = useSelector(authSelectors.getUserAlias)

    const _360views = useSelector(menuSelectors.getViews)
    const updated360Views = _360views?.filter(el => !getHidden360Views.includes(el.text))
    const bizElements = useSelector(menuSelectors.getTopNav)
    const updatedBizElements = bizElements?.filter(el => !getHiddenBizElements.includes(el.text))
    const shortcuts = useSelector(authSelectors.getShortcuts)

    const dispatch = useDispatch()

    const { appName } = useParams()
    const navigate = useNavigate()
    const applicationError = !applications.includes(appName)

    const [expanded, setExpanded] = useState(null)

    const classes = useStyles()

    const orderedModules = modules && [...modules].sort((a, b) => a.title.localeCompare(b.title))

    const handleChange = panel => (e, isExpanded) => {
        setExpanded(isExpanded ? panel : null)
    }

    const handleNavigation = (to, isExternal, type) => {
        if (isExternal && to) openInNewTab(to)
        else if (type === 'viewId') dispatch(navigationCreators.goToPerspective(to))
        else if (type === 'widgetId') dispatch(navigationCreators.goTo(routePaths.WIDGET, to))
        else if (type === 'elementName') dispatch(navigationCreators.goTo(routePaths.ELEMENT, to))

        return onClose()
    }

    return (
        <div className={classes.container}>
            {userAlias && (
                <div className={classes.nameWrapper}>
                    <Avatar className={classes.avatar}>{userAlias[0].toUpperCase()}</Avatar>
                    <Typography variant="h6" className={classes.name}>
                        {userAlias}
                    </Typography>
                </div>
            )}

            <div className={classes.content}>
                <Typography className={classes.label}>Select Cloud</Typography>
                <Panel
                    expanded={expanded === 'application'}
                    title={applicationError ? null : currentApplication}
                    onChange={handleChange('application')}
                >
                    {applications
                        .filter(app => applicationError || app !== currentApplication)
                        .map(app => ({
                            text: app,
                            onClick: () => navigate(`/${app}/select`),
                        }))}
                </Panel>

                <Typography className={classes.label}>Application</Typography>
                <Panel expanded={expanded === 'module'} title={moduleTitle} onChange={handleChange('module')}>
                    {orderedModules &&
                        orderedModules
                            .filter(({ title }) => title !== moduleTitle)
                            .map(({ name, title }) => ({
                                text: title,
                                onClick: () => {
                                    dispatch(navigationCreators.goToModule(name))
                                    onClose()
                                },
                            }))}
                </Panel>

                <Divider />

                <Typography className={classes.label}>Navigation</Typography>
                <List className={classes.list}>
                    <ListItem
                        key={'home'}
                        button
                        className={classes.listItem}
                        onClick={() => {
                            dispatch(navigationCreators.goTo(routePaths.HOME))
                            onClose()
                        }}
                    >
                        <ListItemIcon>
                            <House />
                        </ListItemIcon>
                        <ListItemText primary="Home" className={classes.itemText} />
                    </ListItem>
                </List>

                {updatedBizElements && updatedBizElements.length && (
                    <Panel
                        expanded={expanded === 'businessElements'}
                        title={'Business Elements'}
                        onChange={handleChange('businessElements')}
                    >
                        {updatedBizElements.map(el => {
                            return {
                                text: el.text,
                                onClick: () => handleNavigation(el.to, el.isExternal, el.type),
                            }
                        })}
                    </Panel>
                )}

                {updated360Views &&
                    updated360Views.length &&
                    updated360Views.map(views => (
                        <>
                            {!views.subMenuConfig && (
                                <List className={classes.list}>
                                    <ListItem
                                        key={views.text}
                                        button
                                        className={classes.listItem}
                                        onClick={() => handleNavigation(views.to, views.isExternal, views.type)}
                                    >
                                        <ListItemText primary={views.text} className={classes.itemText} />
                                    </ListItem>
                                </List>
                            )}
                            {views.subMenuConfig && views.subMenuConfig.length && (
                                <Panel
                                    expanded={expanded === views.text}
                                    title={views.text}
                                    onChange={handleChange(views.text)}
                                    key={views.text}
                                >
                                    {views.subMenuConfig.map(el => {
                                        return {
                                            text: el.text,
                                            onClick: () => handleNavigation(el.to, el.isExternal, el.type),
                                        }
                                    })}
                                </Panel>
                            )}
                        </>
                    ))}

                {shortcuts && shortcuts.length > 0 && (
                    <Panel expanded={expanded === 'shortcuts'} title={'Shortcuts'} onChange={handleChange('shortcuts')}>
                        {shortcuts.map(el => {
                            return {
                                text: el.text,
                                onClick: () => handleNavigation(el.to, el.isExternal, el.type),
                            }
                        })}
                    </Panel>
                )}
            </div>

            <Button variant="outlined" className={classes.signout} onClick={() => dispatch(authCreators.logout())}>
                Sign Out
            </Button>
        </div>
    )
}

const usePanelStyles = makeStyles(({ palette, spacing, border }) => ({
    panel: {
        boxShadow: 'inherit',
        border: border.default,
        borderRadius: border.radius.round,
        '&:before': { height: 0 },
    },
    expanded: { margin: '0 !important' },
    summary: { minHeight: '0 !important', margin: `${spacing(0, 0)} !important`, padding: spacing(0, 1) },
    summaryContent: { margin: `${spacing(1, 0)} !important`, fontWeight: 'bold' },
    details: { padding: 0 },
    list: { width: '100%', paddingLeft: spacing(1.5) },
    listItem: { borderTop: `1px solid ${palette.grayscale.lighter}`, paddingLeft: spacing(2) },
}))

function Panel({ expanded, title, onChange, children }) {
    const classes = usePanelStyles()

    if (!children) return null

    return (
        <Accordion
            expanded={expanded}
            onChange={onChange}
            classes={{ root: classes.panel, expanded: classes.expanded }}
        >
            <AccordionSummary
                sx={{ flexDirection: 'row-reverse' }}
                classes={{ root: classes.summary, content: classes.summaryContent }}
                expandIcon={children.length > 0 && expanded ? <RemoveRounded /> : <AddRounded />}
            >
                <Typography color={title ? 'inherit' : 'error'}>{title || 'Select'}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <List className={classes.list}>
                    {children.map(({ text, onClick }) => (
                        <ListItemButton key={text} className={classes.listItem} onClick={onClick}>
                            {text}
                        </ListItemButton>
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
    )
}

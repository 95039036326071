const getCurrentAppName = state => state.applications.current

const getAppInfo = state => state.applications.appInfo

const getLastUpdateDate = state => state.applications.lastUpdateDate

const getAllApplications = state => state.applications.authorizedApps?.map(app => app.AppName)

const getIsPending = state => state.applications.isPending

const getPublicAccessEnabled = state => state.applications.publicAccessEnabled

const getAllAuthorizedApps = state => state.applications.authorizedApps

const getAuthorizedAppInfo = (state, appName) => state.applications.authorizedApps?.find(app => app.AppName === appName)

export const applicationSelectors = {
    getCurrentAppName,
    getAppInfo,
    getLastUpdateDate,
    getAllApplications,
    getIsPending,
    getPublicAccessEnabled,
    getAllAuthorizedApps,
    getAuthorizedAppInfo,
}

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

import '../styles/FullscreenWidget.css'
import FullScreenContainer from '../containers/FullScreenContainer'
import { widgetSelectors } from '../selectors'
import NavTitle from '../containers/NavTitle'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/material'

const useStyles = makeStyles(() => ({
    slideIndicatorWrapper: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        width: '100%',
        marginTop: '20px',
    },
    slideIndicator: {
        background: '#000000',
        color: '#ffffff',
        width: 86,
        height: 28,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

export default function FullscreenWidgetView() {
    const classes = useStyles()
    const { perspectiveID } = useParams()
    const [start] = useSearchParams()
    const location = useLocation()
    const networkContext = location.state.context

    const configs = useSelector(s => widgetSelectors.getWidgetConfigsForPerspective(s, perspectiveID))

    const [widgetIndex, setWidgetIndex] = useState(0)
    const [inputValue, setInputValue] = useState(() => initInputValue(start.get('start')))

    function initInputValue(start) {
        if (!start) return 0
        const index = configs.findIndex(config => config.Id === start)
        setWidgetIndex(index)
        return Math.max(index, 0)
    }

    useEffect(() => {
        document.documentElement.style.setProperty('--thumb-width', `calc(100vw/${configs.length})`)
    }, [])

    return (
        <div className="fullscreen-widget-view">
            <div className="fullscreen-widget-title-div">
                <NavTitle />
            </div>
            <Box sx={{ position: 'relative' }}>
                <FullScreenContainer widgets={configs} networkContext={networkContext} goTo={Math.round(inputValue)} />
            </Box>
            <div className={classes.slideIndicatorWrapper}>
                {configs.length > 1 && (
                    <input
                        onChange={e => {
                            setInputValue(e.target.value)
                            setWidgetIndex(Math.round(e.target.value))
                        }}
                        onTouchEnd={e => {
                            setInputValue(Math.round(e.target.value))
                            setWidgetIndex(Math.round(e.target.value))
                        }}
                        onMouseUp={e => {
                            setInputValue(Math.round(e.target.value))
                            setWidgetIndex(Math.round(e.target.value))
                        }}
                        value={inputValue}
                        type="range"
                        min={0}
                        max={configs.length - 1}
                        step={0.0001}
                        style={{ marginBottom: '8px' }}
                    />
                )}
                <div className={classes.slideIndicator}>
                    {widgetIndex + 1} of {configs ? configs.length : 1}
                </div>
            </div>
        </div>
    )
}

import produce from 'immer'
import { LOCATION_CHANGE } from 'redux-first-history'
import { authTypes } from '../actions/types'

const initialState = {
    isPending: false,
    actAs: null,
}

export default (state = initialState, action) => {
    const { payload } = action
    switch (action.type) {
        case LOCATION_CHANGE:
            return { ...state, isPending: false }
        case authTypes.LOGIN_ATTEMPT:
            return { isPending: true }
        case authTypes.LOGIN_SUCCESS:
            return { isPending: false, user: user(state.user, action), actAs: state?.actAs ? state?.actAs : null }
        case authTypes.LOGIN_FAILURE:
            if (!payload.error) return { isPending: false, error: true }
            return { isPending: false, error: { ...payload.error.body, status: payload.error.status } }
        case authTypes.SET_ACTAS:
            return { ...state, actAs: action.payload.value }
        case authTypes.DELETE_ACTAS:
            return { ...state, actAs: null }
        case authTypes.SET_PERMISSIONS:
            return { ...state, user: { ...state.user, permissions: action.payload } }
        case authTypes.USER_PREFERENCES_SAVE_REQUEST:
        case authTypes.USER_PREFERENCES_SAVE_SUCCESS:
        case authTypes.USER_PREFERENCES_SAVE_FAILURE:
        case authTypes.USER_PROFILE_REQUEST:
        case authTypes.USER_PROFILE_SUCCESS:
        case authTypes.USER_PROFILE_FAILURE:
        case authTypes.USER_PREF_UPDATE:
        case authTypes.APP_PREF_UPDATE:
            return { ...state, user: user(state.user, action) }
        default:
            return state
    }
}

const user = (state = {}, action) => {
    const { payload } = action
    switch (action.type) {
        case authTypes.LOGIN_SUCCESS: {
            const { profile } = payload.user
            const accessKey = profile.TADAAccessKey
            const userInfo = JSON.parse(profile.TADAUserDetails)
            const {
                FirstName,
                LastName,
                ScreenAlias,
                UserId,
                Permissions,
                Email,
                IsCoreSuperUser,
                CompanyId,
                DomainId,
                ScenarioPublishPermissions = {},
                ScenarioApprovalPermissions = {},
                ContextDefaultNetworkFilters,
            } = userInfo

            return {
                accessKey,
                firstName: FirstName,
                lastName: LastName,
                alias: ScreenAlias,
                userId: UserId,
                isCoreSuperUser: IsCoreSuperUser,
                preferences: {
                    appPrefs: [],
                },
                permissions: Permissions,
                scenarioPublishPermissions: ScenarioPublishPermissions,
                scenarioApprovalPermissions: ScenarioApprovalPermissions,
                email: Email,
                ContextDefaultNetworkFilters,
                companyId: CompanyId,
                domainId: DomainId,
            }
        }
        case authTypes.USER_PREFERENCES_SAVE_REQUEST:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    isPending: true,
                },
            }
        case authTypes.USER_PREF_UPDATE:
            return {
                ...state,
                preferences: {
                    appPrefs: payload.preferences,
                },
            }
        case authTypes.USER_PREFERENCES_SAVE_SUCCESS: {
            const { preferences } = payload

            const { appPrefs } = state.preferences
            const newAppPrefs = appPrefs
                ? produce(appPrefs, draft => {
                      preferences.forEach(pref => {
                          const index = draft.findIndex(
                              d => d.Key === pref.Key && (!d.VisorId || d.VisorId === pref.VisorId)
                          )
                          if (index > -1) {
                              if (pref.Value != null) draft[index].Value = pref.Value
                              else delete draft[index]
                          } else {
                              if (pref.Value != null) draft.push(pref)
                          }
                      })
                  })?.filter(p => Boolean(p))
                : preferences

            return {
                ...state,
                preferences: {
                    appPrefs: newAppPrefs,
                    isPending: false,
                },
            }
        }
        case authTypes.USER_PREFERENCES_SAVE_FAILURE:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    isPending: false,
                },
            }
        case authTypes.USER_PROFILE_REQUEST:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    isPending: true,
                },
            }
        case authTypes.USER_PROFILE_SUCCESS: {
            const { user } = payload
            const { UserInfo } = user
            const { FirstName, LastName, ScreenAlias, UserId, Preferences } = UserInfo

            return {
                ...state,
                firstName: FirstName,
                lastName: LastName,
                alias: ScreenAlias,
                userId: UserId,
                preferences: { ...Preferences, isPending: false },
            }
        }
        case authTypes.USER_PROFILE_FAILURE:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    isPending: false,
                },
            }
        default:
            return state
    }
}

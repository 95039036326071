import { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppTour, Logout, User, Help } from 'genesis-suite/icons'
import { MenuList, MenuItem, ListItemIcon, ListItemText, Popover, Typography, Hidden, Avatar } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { authCreators, tourCreators } from '../actions/creators'
import { logEvent } from '../lib/amplitudeClient'
import { authSelectors } from '../selectors'
import { TourTag } from '../lib/tourSteps'
import ActAsAnotherUser from './widgets2/ActAsAnotherUser'

const { ADMIN_PORTAL } = window.TADA_APIS

const buttonSize = 30

const useStyles = makeStyles(theme => ({
    titleWrapper: { padding: '10px 10px 0 10px', display: 'flex', justifyContent: 'space-between' },
    closeButton: { padding: '4px', marginLeft: '15px' },
    avatar: {
        width: buttonSize,
        height: buttonSize,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: 22,
        '&:hover': {
            cursor: 'pointer',
            opacity: 1,
        },
    },
}))

export default function UserProfile() {
    const userAlias = useSelector(authSelectors.getUserAlias)
    const userId = useSelector(authSelectors.getUserId)
    const permission = useSelector(authSelectors.getAppPermission)
    const dispatch = useDispatch()
    const signout = () => dispatch(authCreators.logout())
    const openAppTour = () => dispatch(tourCreators.openTour())

    const [openPopover, setOpenPopover] = useState(false)

    const classes = useStyles()
    const anchorRef = useRef(null)

    const name = userAlias || userId || 'Empty'

    const openZendesk = () => {
        window.open('https://tada.zendesk.com/hc/en-us', '_blank')
        logEvent('OPEN_ZENDESK')
    }

    const openAdminPortal = () => {
        window.open(`${ADMIN_PORTAL}/account`, '_blank')
        logEvent('OPEN_ADMIN_PORTAL')
    }

    const handleOption = cb => e => {
        setOpenPopover(false)
        cb(e)
    }

    const options = [
        ...(ADMIN_PORTAL ? [{ text: 'Manage account', Icon: User, onClick: handleOption(openAdminPortal) }] : []),
        { text: 'App tour', Icon: AppTour, onClick: handleOption(openAppTour) },
        { text: 'Support center', Icon: Help, onClick: handleOption(openZendesk) },
        { text: 'Sign out', Icon: Logout, onClick: handleOption(signout) },
    ]

    return (
        <Hidden mdDown>
            <Avatar
                ref={anchorRef}
                data-tour={TourTag.UserProfile}
                className={classes.avatar}
                onClick={() => setOpenPopover(true)}
            >
                {name[0].toUpperCase()}
            </Avatar>

            <Popover
                id="userinfo-popover"
                open={openPopover}
                anchorEl={anchorRef.current}
                onClose={() => setOpenPopover(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    style: { width: '15%' },
                }}
            >
                <div className={classes.titleWrapper}>
                    <Typography variant="h6">Welcome, {name}</Typography>
                </div>
                {permission === 'Owner' && <ActAsAnotherUser />}
                <MenuList>
                    {options.map(({ text, Icon, onClick }) => (
                        <MenuItem key={text} button onClick={onClick}>
                            <ListItemIcon>
                                <Icon sx={{ color: 'text.primary' }} />
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </MenuItem>
                    ))}
                </MenuList>
            </Popover>
        </Hidden>
    )
}

import { SyncFusionRTE } from 'genesis-suite/components'
import { useCallback, useMemo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { applicationSelectors, authSelectors } from '~/selectors'
import { uploadService, baseUrls } from '~/lib/services'
import { removeImgSrcFromHTML, restoreImageSrcFromTokens } from '~/lib/utils'

interface RichTextEditorComponentProps {
    editable?: boolean
    minHeight?: number
    value: string
    onChange: (value: string, isAutoSaveOn?: boolean) => void
    enableAutoSave?: boolean
}
export default function RichTextEditorComponent({
    editable = true,
    minHeight,
    value,
    onChange,
    enableAutoSave,
}: RichTextEditorComponentProps) {
    const currentAppName = useSelector(applicationSelectors.getCurrentAppName)
    const accessKey = useSelector(authSelectors.getAccessKey)
    const [rteValue, setRteValue] = useState(value)
    const [isAutoSaveOn, setIsAutoSaveOn] = useState(enableAutoSave ? true : false)

    const onValueChange = useCallback(
        val => {
            const updatedValue = removeImgSrcFromHTML(val)
            onChange?.(updatedValue, isAutoSaveOn)
        },
        [isAutoSaveOn, onChange]
    )

    useEffect(() => {
        setRteValue(restoreImageSrcFromTokens(value, baseUrls.integration, accessKey))
    }, [value])

    const imageHandlerConfig = useMemo(
        () => ({
            getTokenForFile: async (file: File): Promise<string> => {
                const fileData = new FormData()
                fileData.append('file', file, file.name)
                const response = await uploadService.uploadFile(fileData, null, null, false)
                return response
            },

            getSrcUrlFromToken: (token: string): string => {
                return `${baseUrls.integration}/files?fileToken=${token}&accessKey=${accessKey || ''}`
            },
        }),
        [accessKey]
    )
    const handleAutoSaveToggle = (isAutoSaveOn: boolean) => {
        setIsAutoSaveOn(isAutoSaveOn)
    }
    return (
        <SyncFusionRTE
            minHeight={minHeight}
            value={rteValue ?? ''}
            onChange={onValueChange}
            imageHandlerConfig={imageHandlerConfig}
            editable={editable}
            isAutoSaveOn={isAutoSaveOn}
            onAutoSaveToggle={handleAutoSaveToggle}
            enableAutoSave={enableAutoSave}
        />
    )
}

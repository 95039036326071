import { push } from 'redux-first-history'
import { matchPath } from 'react-router-dom'
import isEqual from 'lodash/isEqual'

import { addQueryParams, buildRoute } from 'genesis-suite/utils'
import { history } from '../../store/store'
import { navigationTypes } from '../types'
import {
    authSelectors,
    navigationSelectors,
    applicationSelectors,
    moduleSelectors,
    filterSelectors,
} from '../../selectors'
import { routePaths } from '../../lib/routes'
import { widgetConstants } from '../../constants'
import { authCreators } from './auth.creators'

/**
 * go to listed route in current application and module
 * @param {string} route route path as defined in routes.js
 * @param {string=} id optional. id of item (i.e. perspective)
 * @param {*=} routeState optional. state object to pass
 * @param {*=} params optional. object of query params
 */
const goTo = (route, id, routeState, params) => (dispatch, getState) => {
    const state = getState()
    const appName = applicationSelectors.getCurrentAppName(state)
    const moduleName = moduleSelectors.getModuleName(state)
    if (!moduleName) return

    let path = buildRoute(route, appName, moduleName, id)
    if (params) path = addQueryParams(path, params)
    dispatch(goToPath(path, routeState))
}

/**
 * go to full url path
 * @param {string} path url path string
 * @param {*=} routeState optional. state object to pass
 */
const goToPath = (path, routeState) => (dispatch, getState) => {
    const { pathname: oldPath, state: oldState } = history.location

    // TODO: add confirm popup "you will lose changes, proceed?"
    if (matchPath(path, oldPath) && isEqual(oldState, routeState)) return

    dispatch(push(path, routeState))
}

/**
 * go to perspective
 * @param {string} id
 * @param {*=} routeState optional. state object to pass
 * @param {*=} params optional. object of query params
 */
const goToPerspective = (id, routeState, params) => (dispatch, getState) => {
    const state = getState()
    const isPublic = authSelectors.getIsPublicLogin(state)

    if (isPublic) {
        const publicId = authSelectors.getUserId(state)
        let path = `${routePaths.PUBLIC}/${publicId}/perspective/${id}`
        if (params) path = addQueryParams(path, params)
        dispatch(goToPath(path, routeState))
    } else {
        dispatch(goTo(routePaths.PERSPECTIVE, id, routeState, params))
    }
}

/**
 * Navigate to specified module (or default module if no name provided)
 * @param {string=} moduleName optional. module name to navigate to
 */
const goToModule = moduleName => async (dispatch, getState) => {
    const state = getState()
    const appName = applicationSelectors.getCurrentAppName(state)
    const _moduleName =
        moduleName || authSelectors.getDefaultModule(state) || moduleSelectors.getAllModules(state)?.[0]?.name
    dispatch(authCreators.checkActAs())
    dispatch(push(buildRoute(routePaths.HOME, appName, _moduleName)))
}

const setPendingRoute = (path, context) => {
    return {
        type: navigationTypes.SET_PENDING_ROUTE,
        payload: { path, context },
    }
}

const pendingRouteComplete = () => {
    return {
        type: navigationTypes.PENDING_ROUTE_COMPLETE,
    }
}

const goToPendingRoute = () => (dispatch, getState) => {
    const state = getState()

    const pendingRoute = navigationSelectors.getPendingRoute(state)
    if (!pendingRoute) return dispatch(push(routePaths.SELECT))

    dispatch(push({ pathname: pendingRoute.path, state: pendingRoute.context }))
    dispatch(pendingRouteComplete())
}

const goToDashboardWithFilters =
    (id, filters, interactionMode = widgetConstants.Interactions.INFINITE) =>
    (dispatch, getState) => {
        const state = getState()
        let newFilters = filters

        if (interactionMode === widgetConstants.Interactions.DRILLDOWN) {
            const currentFilters = filterSelectors.getBuilderFilters(state) || []
            const normalizedFilters = currentFilters.map(({ source, ...f }) => f)
            newFilters = [...filters, ...normalizedFilters]
        }

        dispatch(goToPerspective(id, { filters: newFilters }))
    }

export const navigationCreators = {
    goTo,
    goToPath,
    goToPerspective,
    goToModule,
    setPendingRoute,
    goToPendingRoute,
    goToDashboardWithFilters,
}

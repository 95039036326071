"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  integration
}) => generator({
  get: modelName => ({
    baseURL: integration,
    method: 'get',
    url: `notifications`,
    config: {
      headers: {
        'x-tadacompress': 'gzip'
      },
      params: {
        modelName
      }
    }
  }),
  getById: notificationId => ({
    baseURL: integration,
    method: 'get',
    url: `notifications`,
    config: {
      params: {
        notificationId
      }
    }
  }),
  getBusinessRules: modelName => ({
    baseURL: integration,
    method: 'get',
    url: `businessrules`,
    config: {
      params: {
        modelName
      }
    }
  }),
  getRuleSubscriptions: (modelName, ruleId) => ({
    baseURL: integration,
    method: 'get',
    url: `businessrules/rulesubscriptions`,
    config: {
      params: {
        modelName,
        ruleId,
        useUserId: true
      }
    }
  }),
  saveRuleSubscriptions: ({
    ruleId,
    email,
    sms
  }) => ({
    baseURL: integration,
    method: 'post',
    url: `businessrules`,
    config: {
      params: {
        ruleId,
        email,
        sms,
        popup: false
      }
    }
  }),
  archiveNotification: (notificationId, modelName) => ({
    baseURL: integration,
    method: 'post',
    url: `notifications/dismiss`,
    config: {
      params: {
        notificationId,
        modelName
      }
    }
  })
});
exports.default = _default;
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { PropertyMetaWithSemantic } from 'genesis-suite/types/networkTypes'
import { searchService } from '../lib/services'
import { applicationSelectors, moduleSelectors, widgetSelectors } from '../selectors'
import useProperties from './useProperties'

export default function useSearch(query: string, widgetId?: string, maxResults?: number): [ResultItem[], boolean] {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const cloudName = useSelector(moduleSelectors.getModuleCloud)
    const modelName = useSelector(applicationSelectors.getCurrentAppName)
    const widgets = useSelector(widgetSelectors.getWidgetConfigs)
    const properties = useProperties()

    const [searchResults, setSearchResults] = useState<ResultItem[]>(null)
    const [fetching, setFetching] = useState<boolean>(false)

    useEffect(() => {
        if (searchResults) setSearchResults(null)
        if (!query || !modelName || !properties?.length) return

        let cancelCall
        async function performSearch() {
            const params: Params = { cloudName, modelName, query, widgetId }
            try {
                const results = await searchService.perform(params, c => (cancelCall = c))

                let docs = results?.EASearchDocs
                if (!docs?.length) return setSearchResults([])

                if (maxResults) docs = docs.splice(0, maxResults)

                const formattedResults = docs.map(r => buildResult(r, properties, widgets, params))
                setSearchResults(formattedResults)
            } catch (error) {
                console.error(error)
                showSnackbar('Error occurred while searching', { variant: 'error' })
            } finally {
                setFetching(false)
            }
        }

        setFetching(true)
        performSearch()

        return () => cancelCall?.()
    }, [query, properties, modelName])

    return [searchResults, fetching]
}

interface Params {
    cloudName: string
    modelName: string
    query: string
    widgetId?: string
}

interface PropertyMetaWithSemanticAndValue extends PropertyMetaWithSemantic {
    value: any
}

interface ResultItem {
    displayProperty: PropertyMetaWithSemanticAndValue
    context: any
    perspectiveId: string
    nodeName: string
}

function buildResult(result, properties: PropertyMetaWithSemantic[], widgets, params: Params): ResultItem {
    const { Attributes, ObjectName: nodeName } = result
    const nodeProperties = properties
        .filter(p => p.container.name === nodeName)
        .map(p => ({ ...p, value: Attributes[p.displayName] }))

    const primaryProperty = nodeProperties.find(p => p.isPrimary)
    const contextDisplayProperty = getContextDisplayProperty(nodeProperties) || primaryProperty
    const contextValue = contextDisplayProperty?.value
    const { query } = params
    let displayProperty = getDisplayProperty(nodeProperties, query)
    if (!displayProperty || contains(contextValue, query)) displayProperty = contextDisplayProperty

    const context = buildContext(nodeName, primaryProperty, contextDisplayProperty, params)
    const perspectiveId = getDefaultPerspectiveId(nodeName, widgets)
    return { displayProperty, context, perspectiveId, nodeName }
}

/** find the first property that contains 'name' in semanticType or name  */
function getContextDisplayProperty(properties: Array<PropertyMetaWithSemanticAndValue>) {
    return properties.find(property => {
        const isSemanticDataType = contains(property.semanticType?.type, ['description', 'name'])
        const isNameType = contains(property.name, ['description', 'name'])
        return isSemanticDataType || isNameType
    })
}

/** find first property that contains search text */
function getDisplayProperty(properties: Array<PropertyMetaWithSemanticAndValue>, query: string) {
    return properties.find(p => contains(p.value, query))
}

const buildContext = (
    nodeName,
    primary: PropertyMetaWithSemanticAndValue,
    display: PropertyMetaWithSemanticAndValue,
    params: Params
) => {
    const { cloudName, modelName } = params
    const fieldName = primary?.name
    const displayFieldName = display?.name
    return {
        CloudName: cloudName,
        ModelName: modelName,
        Type: 'Node',
        Name: nodeName,
        Value: primary?.value,
        FieldName: fieldName,
        DisplayFieldName: displayFieldName,
        DisplayValue: display?.value,
    }
}

function getDefaultPerspectiveId(nodeName: string, widgets: { [id: string]: any }) {
    for (const widget of Object.values(widgets)) {
        if (!widget.ContainerConfig?.IsDefault) continue
        if (widget.Element.trim().toLowerCase() === nodeName.toLowerCase().trim()) return widget.Id
    }
}

/** Test if text string contains search string (or multiple search strings if array) */
function contains(text: any, search: string | string[]) {
    if (!text || typeof text !== 'string') return false

    const lowerCase = text.toLocaleLowerCase()

    if (typeof search === 'string') {
        return lowerCase.indexOf(search.toLocaleLowerCase()) > -1
    } else {
        return search.some(s => lowerCase.indexOf(s.toLocaleLowerCase()) > -1)
    }
}

import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { InfoOutlined as Info } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { moduleCreators } from '~/actions/creators'
import { moduleService } from '~/lib/services'
import { applicationSelectors, authSelectors, menuSelectors, moduleSelectors, widgetSelectors } from '~/selectors'

const useStyles = makeStyles(({ spacing, palette }) => ({
    root: { width: '100%', padding: spacing(), height: '100%' },
    infoIcon: {
        color: palette.primary.light,
        marginLeft: 8,
    },
    autoLaunchDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
}))

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

export function ManageMobileAppearance() {
    const classes = useStyles()
    const dispatch = useDispatch()

    const menuItems = useSelector(menuSelectors.getTopNav)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const visorId = useSelector(moduleSelectors.getModuleId)
    const permission = useSelector(authSelectors.getAppPermission)
    const isAdmin = permission === 'Admin' || permission === 'Owner'
    const views = useSelector(menuSelectors.getViews)
    const _360Items = views.map(({ subMenuConfig, ...rest }) => ({ items: subMenuConfig, ...rest }))
    const appOptions = JSON.parse(useSelector(moduleSelectors.getAppOptions) || null)
    const perspectives = useSelector(widgetSelectors.getAllPerspectives)

    const getSelectedBusinessViews = appOptions?.hideBusinessElements ?? []
    const getSelected360Views = appOptions?.hide360Views ?? []
    const [selectedBusinessEle, setSelectedBusinessEle] = useState(getSelectedBusinessViews)
    const [selectedViews, setSelectedViews] = useState(getSelected360Views)
    const [isAutoLaunch, setIsAutoLaunch] = useState(appOptions?.isAutoLaunchDisabled ?? false)
    const [selectedPerspectiveId, setSelectedPerspectiveId] = useState(appOptions?.autoLaunchPerspectiveId ?? '')
    const [dirty, setDirty] = useState(false)

    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const handleChange = (value, isView = false) => {
        const updatedValues = value.map(item => item.text)
        isView ? setSelectedViews(updatedValues) : setSelectedBusinessEle(updatedValues)
        setDirty(true)
    }

    const handlePerspectiveChange = value => {
        setSelectedPerspectiveId(value ? value.Id : '')
        setDirty(true)
    }

    const saveOptions = async () => {
        const options = {
            visorId,
            appName,
            appOptions: JSON.stringify({
                ...appOptions,
                hideBusinessElements: selectedBusinessEle,
                hide360Views: selectedViews,
                isAutoLaunchDisabled: isAutoLaunch,
                autoLaunchPerspectiveId: selectedPerspectiveId === '' ? '_home' : selectedPerspectiveId,
            }),
        }

        try {
            await moduleService.updateAppOptions(options)
        } catch (ex) {
            let message = 'Layout preferences cannot be saved'
            if (ex?.Message) message = ex.Message
            showSnackbar(message, { variant: 'error' })
            return
        }

        showSnackbar('Saved layout preferences', { variant: 'success' })
        dispatch(moduleCreators.updateModuleOptions(options))
    }

    return (
        <div className={classes.root}>
            <div>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6" sx={{ paddingBottom: '5px' }}>
                        Hide Business Elements
                    </Typography>
                    {isAdmin && (
                        <Button variant="contained" disabled={!dirty} onClick={saveOptions}>
                            Save
                        </Button>
                    )}
                </Box>
                <Box>
                    <FormControl>
                        <Autocomplete
                            multiple
                            disableCloseOnSelect
                            getOptionLabel={option => option.text}
                            renderOption={(props, option, { selected }) => {
                                const { ...optionProps } = props
                                return (
                                    <li {...optionProps}>
                                        <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                                        {option.text}
                                    </li>
                                )
                            }}
                            options={menuItems}
                            style={{ minWidth: '200px' }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Business Elements"
                                    placeholder="Business Elements"
                                    variant="outlined"
                                />
                            )}
                            value={menuItems.filter(item => selectedBusinessEle.includes(item.text))}
                            onChange={(e, value) => handleChange(value)}
                        />
                    </FormControl>
                </Box>
                <Box mt={2} />
                <Box>
                    <Typography variant="h6" sx={{ paddingBottom: '5px' }}>
                        Hide 360 Views
                    </Typography>
                    <Box>
                        <FormControl>
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                getOptionLabel={option => option.text}
                                renderOption={(props, option, { selected }) => {
                                    const { ...optionProps } = props
                                    return (
                                        <li {...optionProps}>
                                            <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                                            {option.text}
                                        </li>
                                    )
                                }}
                                options={_360Items}
                                style={{ minWidth: '200px' }}
                                renderInput={params => (
                                    <TextField {...params} label="Views" placeholder="360Views" variant="outlined" />
                                )}
                                value={_360Items.filter(item => selectedViews.includes(item.text))}
                                onChange={(e, value) => handleChange(value, true)}
                            />
                        </FormControl>
                    </Box>
                </Box>
                <Box mt={2} />
                <Box className={classes.autoLaunchDiv}>
                    <FormControlLabel
                        control={
                            <Switch
                                color="primary"
                                checked={isAutoLaunch}
                                onChange={() => {
                                    setIsAutoLaunch(!isAutoLaunch)
                                    setDirty(true)
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        sx={{
                            margin: 0,
                        }}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography display="inline" variant="subtitle1" fontWeight={'bold'}>
                                    Change Auto Launch Behaviour
                                </Typography>
                                <Tooltip
                                    title={
                                        <span>
                                            Enabling this setting will result in the following behavior:
                                            <br />
                                            1. If no perspective is selected, the user will be redirected to the Home
                                            Page.
                                            <br />
                                            2. If a perspective is selected, the user will be redirected to the selected
                                            perspective in the Mobile App after login.
                                        </span>
                                    }
                                >
                                    <Info className={classes.infoIcon} />
                                </Tooltip>
                            </div>
                        }
                        labelPlacement="start"
                    />

                    {isAutoLaunch && (
                        <FormControl>
                            <Autocomplete
                                getOptionLabel={option => option.Title}
                                options={perspectives}
                                style={{ minWidth: '300px' }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Select Perspective"
                                        placeholder="Select Perspective for Auto Launch"
                                        variant="outlined"
                                    />
                                )}
                                value={perspectives.find(p => p.Id === selectedPerspectiveId)}
                                onChange={(e, value) => handlePerspectiveChange(value)}
                            />
                        </FormControl>
                    )}
                </Box>
            </div>
        </div>
    )
}

import { authTypes } from '../types'
import { collaborationService } from '../../api'
import { authService, userService } from '../../lib/services'
import persistor from '../../store/persistor'
import userManager from '../../lib/userManager'
import { applicationSelectors, authSelectors, moduleSelectors } from '../../selectors'
import { logEvent } from '../../lib/amplitudeClient'
import { moduleCreators } from './module.creators'
import { linksCreators } from './links.creators'
import { insightCreators } from './insight.creators'

const loginSuccess = user => {
    return {
        type: authTypes.LOGIN_SUCCESS,
        payload: { user },
    }
}

const logout = () => async () => {
    collaborationService.disconnect()
    persistor.pause()
    await persistor.flush()
    await persistor.purge()
    await userManager.signoutRedirect()
}

const expireSession = () => {
    return {
        type: authTypes.SESSION_EXPIRED,
    }
}

const extendSession = () => {
    return {
        type: authTypes.SESSION_EXTEND,
    }
}

/** Used for components that don't use redux to help keep the session alive */
const pingRedux = () => (dispatch, getState) => {
    const state = getState()
    const user = authSelectors.getUser(state)
    authService.ping(user.accessKey).then(() => dispatch(extendSession()))
}

const saveUserPreferencesForCurrentApplication = preferences => (dispatch, getState) => {
    const application = applicationSelectors.getCurrentAppName(getState())
    return dispatch(saveUserPreferences(application, preferences))
}

const saveUserPreferences = (application, preferences) => {
    return (dispatch, getState) => {
        const state = getState()
        const UserId = authSelectors.getUserId(state)
        const Preferences = { [application]: preferences }

        dispatch(userPreferencesSaveRequest())

        return userService
            .updateAccount({ UserId, Preferences })
            .then(() => {
                dispatch(userPreferencesSaveSuccess(preferences))
                return Promise.resolve()
            })
            .catch(error => {
                dispatch(userPreferencesSaveFailure(error))
                dispatch(userProfileRequest())
                userService
                    .getAccount()
                    .then(profile => {
                        dispatch(userProfileSuccess(profile))
                    })
                    .catch(error => {
                        dispatch(userProfileFailure(error))
                    })
                return Promise.reject(error)
            })
    }
}

const updateApplicationPrefs = pref => (dispatch, getState) => {
    const state = getState()
    const appPrefs = authSelectors.getUserPreferences(state)

    //not all preferences currently use the visorId, so we only want to check for it in the case that this
    //preference is being saved with one
    const moduleId = pref.VisorId ? moduleSelectors.getModuleId(state) : ''
    const filtered = appPrefs ? appPrefs.filter(p => !(p.Key === pref.Key && p.VisorId === moduleId)) : []

    let updatedAppPrefs = [...filtered]
    const prefIndex = filtered.findIndex(p => p.Key === pref.Key)

    if (prefIndex > -1) updatedAppPrefs[prefIndex] = { ...filtered[prefIndex], ...pref }
    else updatedAppPrefs.push(pref)

    dispatch(updateUserPrefs(updatedAppPrefs))
}

/**
 * Update autoLaunch user preference
 * @param {string=} value perspectiveId, '__home' if override module autoShow (go home instead), or undefined to remove preference
 */
const updateAutoLaunchPerspective = value => async (dispatch, getState) => {
    const state = getState()
    const appPrefs = authSelectors.getUserPreferences(state)
    const moduleId = moduleSelectors.getModuleId(state)
    const prevPref = appPrefs
        ? appPrefs.find(pref => pref.Key === 'AutoShowPerspective' && pref.VisorId === moduleId)
        : null

    const pref = {
        Id: prevPref && prevPref.Id,
        Key: 'AutoShowPerspective',
        Value: value ? JSON.stringify({ Id: value }) : null,
        VisorId: moduleId,
    }

    await dispatch(saveUserPreferencesForCurrentApplication([pref]))
    dispatch(updateApplicationPrefs(pref))
}

const updateDefaultModule = moduleTitle => {
    return (dispatch, getState) => {
        const state = getState()
        const appPrefs = authSelectors.getUserPreferences(state)
        const prevPref = appPrefs ? appPrefs.find(pref => pref.Key === 'defaultModule') : null

        const pref = {
            Id: prevPref && prevPref.Id,
            Key: 'defaultModule',
            Value: moduleTitle,
        }

        dispatch(updateApplicationPrefs(pref))
        return dispatch(saveUserPreferencesForCurrentApplication([pref]))
    }
}

const saveShortcuts = shortcuts => dispatch => {
    logEvent('EDIT_SHORTCUTS')

    const preference = {
        Id: null,
        Key: 'Shortcuts',
        Value: JSON.stringify(shortcuts),
    }

    dispatch(updateApplicationPrefs(preference))
    dispatch(saveUserPreferencesForCurrentApplication([preference])).catch(error => {
        console.error(error)
    })
}

const saveFavorite = (Key, value) => (dispatch, getState) => {
    logEvent(`${Key}_UPDATED`)
    const state = getState()
    const preferences = authSelectors.getUserPreferences(state)
    const favorites = preferences && preferences.find(pref => pref.Key === Key)
    const id = favorites ? favorites.Id : null
    const parsed = favorites ? JSON.parse(favorites.Value) : []
    const favs = parsed.includes(value) ? parsed.filter(f => f !== value) : [...parsed, value]

    const preference = {
        Id: id,
        Key,
        Value: JSON.stringify(favs),
    }

    dispatch(updateApplicationPrefs(preference))
    return dispatch(saveUserPreferencesForCurrentApplication([preference]))
}

const updateUserPrefs = preferences => ({
    type: authTypes.USER_PREF_UPDATE,
    payload: { preferences },
})

const userProfileRequest = () => {
    return {
        type: authTypes.USER_PROFILE_REQUEST,
    }
}

const userProfileSuccess = user => {
    return {
        type: authTypes.USER_PROFILE_SUCCESS,
        payload: { user },
    }
}

const userProfileFailure = error => {
    return {
        type: authTypes.USER_PROFILE_FAILURE,
        payload: {
            error,
        },
        error: true,
    }
}

const userPreferencesSaveRequest = () => {
    return {
        type: authTypes.USER_PREFERENCES_SAVE_REQUEST,
    }
}

const userPreferencesSaveSuccess = preferences => {
    return {
        type: authTypes.USER_PREFERENCES_SAVE_SUCCESS,
        payload: { preferences },
    }
}

const userPreferencesSaveFailure = error => {
    return {
        type: authTypes.USER_PREFERENCES_SAVE_FAILURE,
        payload: { error },
        error: true,
    }
}

const updateActAsData = async dispatch => {
    await userManager.signinSilent()
    const user = await userManager.getUser()
    if (user) {
        await dispatch({
            type: authTypes.LOGIN_SUCCESS,
            payload: { user },
        })
    }
}

const updateApplicationModule = async (appName, dispatch, getState) => {
    const state = getState()
    const moduleName = moduleSelectors.getModuleName(state)
    const preferences = await userService.getUserPreferences(appName)
    if (preferences?.length && moduleName) {
        await dispatch(moduleCreators.reloadModule())
        dispatch(linksCreators.get(appName))
        dispatch(insightCreators.getList(appName))
    }
}

const updateSetAsUser = data => {
    return {
        type: authTypes.SET_ACTAS,
        payload: {
            value: data,
        },
    }
}

const setActAs = (data, params) => {
    return (dispatch, getState) => {
        authService.ActAs(params).then(async response => {
            await dispatch({
                type: authTypes.SET_ACTAS,
                payload: {
                    value: data,
                },
            })
            await updateActAsData(dispatch)
            updateApplicationModule(params.modelName, dispatch, getState)
        })
    }
}

const deleteActAs = params => {
    return async (dispatch, getState) => {
        authService.DeActAs(params).then(async response => {
            await dispatch({
                type: authTypes.DELETE_ACTAS,
            })
            await updateActAsData(dispatch)
            updateApplicationModule(params.modelName, dispatch, getState)
        })
    }
}

const checkActAs = () => {
    return async (dispatch, getState) => {
        userService.getAccount().then(async userResponse => {
            const appInfo = applicationSelectors.getAppInfo(getState())
            if (
                userResponse?.UserInfo?.ActingAs?.length > 0 &&
                userResponse?.UserInfo?.ActingAs[0]?.ContextId == appInfo?.appId
            ) {
                const data = {
                    userId: userResponse?.UserInfo?.ActingAs[0]?.UserId,
                    userName: '',
                }
                dispatch(updateSetAsUser(data))
                dispatch(updateActAsData)
            } else {
                await dispatch({
                    type: authTypes.DELETE_ACTAS,
                })
            }
        })
    }
}

export const authCreators = {
    loginSuccess,
    logout,
    expireSession,
    saveUserPreferencesForCurrentApplication,
    updateDefaultModule,
    saveShortcuts,
    updateAutoLaunchPerspective,
    saveFavorite,
    pingRedux,
    updateUserPrefs,
    setActAs,
    deleteActAs,
    updateSetAsUser,
    updateActAsData,
    checkActAs,
}

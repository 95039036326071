import { CssBaseline, ThemeProvider, createTheme } from '@mui/material'
import { StyledEngineProvider } from '@mui/material/styles'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { LicenseInfo } from '@mui/x-license-pro'
import { DefaultErrorBoundary, SwalProvider } from 'genesis-suite/components'
import { SnackbarProvider } from 'notistack'
import { useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useDispatch, useSelector } from 'react-redux'
import './App.css'
import { authCreators, widgetCreators } from './actions/creators'
import ReduxSnackbar from './components/ReduxSnackbar'
import TopLoader from './components/TopLoader'
import { FullScreenDialogProvider } from './components/contexts/FullScreenDialogContext'
import { PopoutProvider } from './components/contexts/PopoutWidgetContext'
import { ConfigProvider } from './components/edit_widget'
import Settings from './components/settings/Settings'
import Dialog from './containers/Dialog'
import useLockout from './hooks/useLockout'
import { setApplicationName, setUserId } from './lib/amplitudeClient'
import useLogRocket from './lib/logRocket'
import { applicationSelectors, authSelectors, moduleSelectors } from './selectors'
import { AuthorizationGateway } from './views'
import AppRouter from './views/routers/AppRouter'
import ManageCookies from './components/settings/ManageCookies'

LicenseInfo.setLicenseKey(
    'f12314dd03314d4d4d3dd1f709938dd3T1JERVI6Mzc0ODYsRVhQSVJZPTE2NzU5NjE4ODEwMDAsS0VZVkVSU0lPTj0x'
)

export default function App() {
    const theme = useSelector(state => state.theme)
    const userId = useSelector(authSelectors.getUserId)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const appDisplayName = useSelector(applicationSelectors.getAppInfo)?.appDisplayName
    const moduleTitle = useSelector(moduleSelectors.getModuleTitle)
    const appOptions = JSON.parse(useSelector(moduleSelectors.getAppOptions) || null)

    const documentTitle = window.TADA_APIS.DOCUMENT_TITLE
    const dispatch = useDispatch()
    useLogRocket()
    useLockout()

    useEffect(() => {
        dispatch(widgetCreators.resetEditingPerspective())
    }, [])

    useEffect(() => {
        if (!moduleTitle) document.title = documentTitle || 'Tada App'
        else document.title = `${documentTitle || appDisplayName || appName} | ${moduleTitle}`
    }, [moduleTitle])

    useEffect(() => {
        if (appOptions && appOptions?.defaultInteraction) {
            dispatch(widgetCreators.changeInteractionType(appOptions?.defaultInteraction))
        }
    }, [appOptions?.defaultInteraction])

    useEffect(() => {
        setUserId(userId)
        setApplicationName(appName)
    }, [userId, appName])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={createTheme(theme)}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <AuthorizationGateway>
                        <SwalProvider>
                            <SnackbarProvider
                                max={3}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                className="snackbar"
                            >
                                <DndProvider backend={HTML5Backend}>
                                    <ConfigProvider>
                                        <PopoutProvider>
                                            <FullScreenDialogProvider>
                                                <DefaultErrorBoundary logout={() => dispatch(authCreators.logout())}>
                                                    <CssBaseline />
                                                    <Settings />
                                                    <Dialog />
                                                    <TopLoader />
                                                    <AppRouter />
                                                    <ReduxSnackbar />
                                                    <ManageCookies />
                                                </DefaultErrorBoundary>
                                            </FullScreenDialogProvider>
                                        </PopoutProvider>
                                    </ConfigProvider>
                                </DndProvider>
                            </SnackbarProvider>
                        </SwalProvider>
                    </AuthorizationGateway>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

import { useState, useCallback, useContext, forwardRef, useMemo } from 'react'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PerspectiveContext } from '~/components/contexts/PerspectiveContext'
import Measure from 'react-measure'
import GridLabel from './GridLabel'
import { getMappedSeries } from './LabelWidgetUtils'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { LabelStyle } from './LabelStyle'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        '& .slick-prev:before, .slick-next:before': {
            color: theme.palette.text.primary,
        },
        '& .slick-next': {
            right: '-24px !important',
            zIndex: 1,
        },
        '& .slick-prev': {
            left: '-24px !important',
            zIndex: 1,
        },
        '&.slick-slide': {
            height: '200px',
        },
    },
    slideIndicatorWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    slideIndicator: {
        background: '#000000',
        color: '#ffffff',
        width: 86,
        height: 28,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

export const MobileTopLabelWidget = forwardRef(({ configs }, ref) => {
    const classes = useStyles()
    const { topLabelData } = useContext(PerspectiveContext)

    const [mWidth, setMWidth] = useState(500)
    const [mHeight, setMHeight] = useState(0)
    const [index, setIndex] = useState(0) // Track current slide index

    const SLICK_HEIGHT = 120

    const sliderSettings = {
        dots: false, // Remove dots
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => setIndex(next), // Update index on slide change
    }

    const topLabelSeries = useMemo(() => {
        return getMappedSeries(configs)
    }, [configs])

    const getProps = useCallback(
        labelSeries => {
            const _labelsConfig = configs.find(obj => obj.Id == labelSeries.config_id)
            const _labelData = topLabelData[labelSeries.config_id]
            const labelsConfig = _labelsConfig.LabelConfig
            const content = _labelData == undefined ? null : _labelData
            const status = _labelData == undefined ? 'fetching' : _labelData?.status
            return { labelsConfig, content, status }
        },
        [topLabelData]
    )

    return (
        <Measure
            client
            onResize={({ client }) => {
                setMWidth(client.width - 60)
                setMHeight(client.height - 80)
            }}
        >
            {({ measureRef }) => (
                <div ref={measureRef} className={classes.container}>
                    <Box mx={5}>
                        <Slider {...sliderSettings}>
                            {topLabelSeries?.map((c, i) => {
                                const props = getProps(c)
                                const { labelStyle, leftStripStyle } = LabelStyle(c, props.content)
                                const updatedStyle = {
                                    ...labelStyle,
                                    width: '100%',
                                    height: SLICK_HEIGHT,
                                }
                                return (
                                    <Box sx={updatedStyle} key={i}>
                                        <Box display="flex" sx={{ height: '100%', width: '100%' }}>
                                            <div style={leftStripStyle}></div>
                                            <GridLabel
                                                {...props}
                                                labelConfig={c}
                                                itemWidth={mWidth}
                                                labelStyle={labelStyle}
                                                slider={true}
                                            />
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Slider>
                    </Box>
                    <div className={classes.slideIndicatorWrapper}>
                        <div className={classes.slideIndicator}>
                            {index + 1} of {topLabelSeries?.length || 1}
                        </div>
                    </div>
                </div>
            )}
        </Measure>
    )
})

export default MobileTopLabelWidget

import {
    Autocomplete,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    TextareaAutosize,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { useMemo, useState } from 'react'

import { WrapperVariant } from '@mui/x-date-pickers/internals'
import { PickersActionBarAction } from '@mui/x-date-pickers/PickersActionBar/PickersActionBar'
import { FieldDisplayType, FormColumn, FormPrompt } from 'genesis-suite/types/visualTypes'
import moment from 'moment'
import { DATE_TIME_FORMAT, getType } from '../../../lib/formUtils'
import { SemanticType } from 'genesis-suite/types/architectureTypes'
import RichTextEditorComponent from '~/components/RichTextEditorComponent'

type Field = FormColumn | FormPrompt
interface FormFieldProps {
    value: any
    field: Field
    onEditComplete: (value: any, isAutoSaveOn?: boolean) => void
    multiEdit?: boolean
    flexLayout?: boolean
    className?: string
    multiple?: boolean
    disabled?: boolean
    disableUnderline?: boolean
    /** (default 'input') whether field is in a table cell or form input */
    location?: 'cell' | 'input'
    onError?: (reason: string, value: any) => void
    showAutocomplete?: boolean
    dataType?: SemanticType
    textAreaHeight?: number
    isNew?: boolean
}

export default function FormField({
    field,
    value,
    multiEdit,
    flexLayout,
    onError,
    multiple,
    className,
    onEditComplete,
    disableUnderline,
    location = 'input',
    disabled: isDisabled,
    showAutocomplete = false,
    dataType,
    textAreaHeight,
    isNew = false,
}: FormFieldProps) {
    const [open, setOpen] = useState(true)
    const [blurred, setBlurred] = useState(false)
    const inTableCell = location === 'cell'
    //Converting px in Rows
    const textAreaRows = Math.floor(textAreaHeight / 22)
    const { editable, required, property, dateFormat, lastUpdateField, autogenerate, autogeneratePrefix, title } = field
    const isAutoGenerated = autogenerate || autogeneratePrefix
    const isMultiPrimary = property?.isPrimary && multiEdit

    const charCount = useMemo(() => {
        return value?.length
    }, [value])

    const disabled = isDisabled || Boolean(!editable || isAutoGenerated || lastUpdateField) || isMultiPrimary
    const emptyError = required && blurred && (value == null || !String(value))

    const getHelperText = () => {
        if (isAutoGenerated) return 'This value is auto generated'
        else if (lastUpdateField) return 'This value is automatically updated'
        else if (emptyError) return 'Field is required'
    }

    const onConditionalChange = (val: string) => {
        if (val?.length <= dataType?.stringLength) {
            onEditComplete(val)
        }
    }

    if (field.customEditor) {
        const { display, values } = field.customEditor
        const correctedDisplay = inTableCell && display === FieldDisplayType.RADIO ? FieldDisplayType.SELECT : display
        const toggleOpen = () => {
            setOpen(!open)
        }

        switch (correctedDisplay) {
            case FieldDisplayType.SELECT:
                // When the showAutocomplete flag is true, we use the Autocomplete component
                // This is necessary due to the stopEditingWhenCellsLoseFocus property of AG Grid, which can interrupt the Autocomplete functionality
                // by causing the editor to close when the Autocomplete dropdown gains focus
                return showAutocomplete ? (
                    <Autocomplete
                        sx={theme => ({
                            '.MuiSvgIcon-root ': {
                                fill: theme.palette.text.primary,
                            },
                        })}
                        multiple={multiple}
                        open={open}
                        value={value || ''}
                        onOpen={toggleOpen}
                        onClose={toggleOpen}
                        onChange={(e, newValue) => onEditComplete(newValue)}
                        disabled={disabled}
                        options={values}
                        getOptionLabel={option => option}
                        renderInput={params => (
                            <TextField
                                {...params}
                                fullWidth
                                required={required}
                                variant="outlined"
                                label={inTableCell ? undefined : title}
                                helperText={getHelperText()}
                                error={emptyError}
                                className={className}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                }}
                                InputLabelProps={{ shrink: true }}
                                onBlur={() => setBlurred(true)}
                            />
                        )}
                    />
                ) : (
                    <TextField
                        select
                        fullWidth
                        required={required}
                        disabled={disabled}
                        value={value || ''}
                        className={className}
                        helperText={getHelperText()}
                        InputProps={{ disableUnderline }}
                        InputLabelProps={{ shrink: true }}
                        error={emptyError}
                        label={inTableCell ? undefined : title}
                        onChange={e => onEditComplete(e.target.value)}
                        SelectProps={{
                            multiple,
                            onBlur: () => setBlurred(true),
                            ...(inTableCell && { open, onClose: () => setOpen(false) }),
                        }}
                    >
                        {values?.map(value => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </TextField>
                )
            case FieldDisplayType.RADIO:
                return (
                    <FormControl>
                        <FormLabel required={required} id={`form-radio-label-${title}`}>
                            {title}
                        </FormLabel>
                        <RadioGroup
                            value={value}
                            aria-labelledby={`form-radio-label-${title}`}
                            onChange={e => onEditComplete(e.target.value)}
                        >
                            {values?.map(value => (
                                <FormControlLabel key={value} value={value} label={value} control={<Radio />} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                )
            case FieldDisplayType.CHECKBOX:
            case FieldDisplayType.TOGGLE:
                const toggleVals = values.length > 1 ? values : [values[0], null]
                const formattedTitle = property.isPrimary ? `${title} *` : title
                const Component = correctedDisplay === FieldDisplayType.CHECKBOX ? Checkbox : Switch
                const control = (
                    <FormControlLabel
                        labelPlacement="top"
                        label={inTableCell ? '' : formattedTitle}
                        sx={{ ml: 0, '& > span': { alignSelf: 'flex-start' } }}
                        control={
                            <Component
                                sx={{ color: 'text.primary' }}
                                checked={value === toggleVals[0]}
                                onChange={e => onEditComplete(e.target.checked ? toggleVals[0] : toggleVals[1])}
                            />
                        }
                    />
                )

                return inTableCell ? (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{control}</Box>
                ) : (
                    <Box alignSelf="flex-start">{control}</Box>
                )
        }
    } else {
        const type = isMultiPrimary ? 'string' : getType(property, dateFormat)
        const textFieldDefaults = {
            required,
            disabled,
            className,
            label: inTableCell ? undefined : title,
            value: value ?? '',
            helperText: getHelperText(),
            InputLabelProps: { shrink: true },
            error: emptyError,
            onBlur: () => setBlurred(true),
        }
        const getActions = (variant: WrapperVariant): PickersActionBarAction[] =>
            variant === 'desktop' ? ['clear', 'today'] : ['clear', 'today', 'cancel', 'accept']
        switch (type) {
            case 'date':
                return (
                    <DatePicker
                        value={value}
                        disabled={disabled}
                        inputFormat={dateFormat}
                        minDate={moment('1900-01-01')}
                        componentsProps={{
                            actionBar: {
                                actions: getActions,
                            },
                            leftArrowButton: {
                                sx: {
                                    color: 'text.primary',
                                },
                            },
                            rightArrowButton: {
                                sx: { color: 'text.primary' },
                            },
                            switchViewButton: {
                                sx: { color: 'text.primary' },
                            },
                        }}
                        onChange={date => onEditComplete(date?.startOf('day').format(DATE_TIME_FORMAT))}
                        onError={(reason, value) => (onError ? onError(reason, value) : null)}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={inTableCell ? undefined : title}
                                required={required}
                                className={className}
                                helperText={getHelperText()}
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                    '& button': { marginRight: 0 },
                                }}
                                error={params.error || emptyError}
                                onBlur={() => setBlurred(true)}
                            />
                        )}
                    />
                )
            case 'dateTime':
                return (
                    <DateTimePicker
                        disableMaskedInput
                        value={value}
                        disabled={disabled}
                        inputFormat={dateFormat}
                        minDate={moment('1900-01-01')}
                        componentsProps={{ actionBar: { actions: getActions } }}
                        onChange={date => onEditComplete(date?.format(DATE_TIME_FORMAT))}
                        onError={(reason, value) => (onError ? onError(reason, value) : null)}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={inTableCell ? undefined : title}
                                required={required}
                                className={className}
                                helperText={getHelperText()}
                                InputLabelProps={{ shrink: true }}
                                sx={{ '& button': { marginRight: 0 } }}
                                error={params.error || emptyError}
                                onBlur={() => setBlurred(true)}
                            />
                        )}
                    />
                )
            case 'number':
                return (
                    <TextField
                        {...textFieldDefaults}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        onChange={e => onEditComplete(e.target.value)}
                    />
                )
            case 'string':
                if (dataType?.supportsMarkup) {
                    return (
                        <Box sx={{ margin: '5px 0' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <FormLabel
                                    required={required}
                                    style={{
                                        color: 'inherit',
                                        fontFamily: 'inherit',
                                        fontSize: '12px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {inTableCell ? undefined : title}
                                </FormLabel>
                            </Box>
                            <RichTextEditorComponent
                                editable={disabled}
                                value={value ?? ''}
                                onChange={(value, isAutoSaveOn) => {
                                    onEditComplete(value, isAutoSaveOn)
                                }}
                                enableAutoSave={true && !isNew}
                            />
                        </Box>
                    )
                }
                if (dataType?.id === 20 || dataType?.id === 21) {
                    //@TODO: Need to update this override styling in TextAreaAutoSize
                    return (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <FormLabel
                                    required={required}
                                    style={{
                                        color: 'inherit',
                                        fontFamily: 'inherit',
                                        fontSize: '12px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {inTableCell ? undefined : title}
                                </FormLabel>
                                <span style={{ opacity: 0.8 }}>
                                    {charCount}/{dataType?.stringLength}
                                </span>
                            </Box>
                            <TextareaAutosize
                                className={className}
                                minRows={textAreaRows <= 3 ? 3 : textAreaRows}
                                onChange={e => onConditionalChange(e.target.value)}
                                required={required}
                                style={{
                                    background: 'inherit',
                                    color: 'inherit',
                                    fontFamily: 'inherit',
                                    borderColor: 'inherit',
                                    outline: 'none',
                                    opacity: disabled ? 0.5 : 1,
                                }}
                                value={value ?? ''}
                                disabled={disabled}
                            />
                        </>
                    )
                } else {
                    return (
                        <TextField
                            {...textFieldDefaults}
                            multiline
                            type={type}
                            maxRows={flexLayout ? 10 : undefined}
                            InputProps={{ disableUnderline }}
                            onChange={e => onEditComplete(e.target.value)}
                        />
                    )
                }
            default:
                return <span>Unsupported field type</span>
        }
    }
}

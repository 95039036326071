import SearchIcon from '@mui/icons-material/Search'
import { ListItemIcon, ListItemText, MenuItem, Popover } from '@mui/material'

export default function ChatAutoComplete({
    suggestions,
    anchorPos,
    openAutoComplete,
    handleClose,
    onClickMenuItem,
    selectedSuggestionIndex,
}) {
    return (
        suggestions?.length > 0 && (
            <Popover
                id="fade-menu"
                open={Boolean(openAutoComplete)}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={anchorPos !== null ? anchorPos : undefined}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                slotProps={{ paper: { sx: { maxWidth: '450px', maxHeight: '450px' } } }}
                disableAutoFocus
                disableEnforceFocus
            >
                {suggestions?.map((item, index) => {
                    return (
                        <MenuItem
                            key={index}
                            selected={index === selectedSuggestionIndex}
                            onClick={() => onClickMenuItem(item)}
                        >
                            <ListItemIcon>
                                <SearchIcon />
                            </ListItemIcon>
                            <ListItemText>{item?.displayProperty?.value}</ListItemText>
                        </MenuItem>
                    )
                })}
            </Popover>
        )
    )
}

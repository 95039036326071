import axios from 'axios'
import { AIResponse, TGPTRequest, TGPTResponse, TGPTResponseType } from 'genesis-suite/types/visualTypes'
import { buildRoute } from 'genesis-suite/utils'
import { isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { routePaths } from '~/lib/routes'
import { aiService, chatService } from '~/lib/services'
import { chatCreators, navigationCreators } from '../../actions/creators'
import {
    applicationSelectors,
    authSelectors,
    chatSelectors,
    filterSelectors,
    getSelectedBackup,
    moduleSelectors,
    scenarioSelectors,
} from '../../selectors'
import AIChatComponent from './AIChatComponent'
import DataResponseTable from './DataResponseTable'
import WidgetPills from './WidgetPills'

export default function AIChatBox({ Header, inputQuestion = null }) {
    const [loading, setLoading] = useState(false)
    const [chatLoading, setChatLoading] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [messages, setMessages] = useState(useSelector(chatSelectors.getAIChatMessages))
    const [chatMessages, setChatMessages] = useState(useSelector(chatSelectors.getChatMessages))
    const userId = useSelector(authSelectors.getUserId)
    const tModule = useSelector(moduleSelectors.getCurrentModule)
    const appInfo = useSelector(applicationSelectors.getAppInfo)
    const chatSessionId = useSelector(chatSelectors.getAIChatSessionId)
    const dispatch = useDispatch()
    const [hasDocuments, setHasDocuments] = useState<boolean>(false)
    const [showStopGenerating, setShowStopGenerating] = useState<boolean>(false)
    const messagesRef = useRef(null)
    const chatMessagesRef = useRef(null)
    const splitScreenOn = useSelector(chatSelectors.isSplitScreenOn)
    const accessKey = useSelector(authSelectors.getAccessKey)
    const contextFilters = useSelector(filterSelectors.currentGlobalFiltersConfiguration)
    const selectedBackup = useSelector(getSelectedBackup)
    const backupVersionId = selectedBackup?.[0]?.Id ?? null
    const scenarios = useSelector(scenarioSelectors.getActiveScenarioIds)
    const version = useSelector(chatSelectors.getAIChatVersion)
    const [responseType, setResponseType] = useState<TGPTResponseType>(TGPTResponseType.Auto)
    const chatSettings = useSelector(chatSelectors.getChatSettings)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const aiChatBoxRef = useRef<HTMLDivElement>(null)
    const chatboxRef = useRef<HTMLDivElement>(null)
    const scrollIntoView = () => {
        aiChatBoxRef.current?.scrollTo(0, aiChatBoxRef.current.scrollHeight)
    }

    const scrollIntoChatView = () => {
        chatboxRef.current?.scrollTo(0, chatboxRef.current.scrollHeight)
    }

    useEffect(() => {
        return () => {
            const updatedMessages = messagesRef.current.map(msg => {
                if (msg.messageType === 'string') {
                    msg.avoidTyping = true
                }

                return msg
            })

            const updatedChatMessages = chatMessagesRef.current.map(msg => {
                if (msg.messageType === 'string') {
                    msg.avoidTyping = true
                }

                return msg
            })

            dispatch(chatCreators.updateAIChatMessages(updatedMessages))
            dispatch(chatCreators.updateChatMessages(updatedChatMessages))
        }
    }, [])

    useEffect(() => {
        messagesRef.current = messages
        chatMessagesRef.current = chatMessages
    }, [messages, chatMessages])

    useEffect(() => {
        scrollIntoView()
    }, [messages])

    useEffect(() => {
        scrollIntoChatView()
    }, [chatMessages])

    useEffect(() => {
        if (version === 'V3.1' && !hasDocuments) setResponseType(TGPTResponseType.Data)
        else setResponseType(TGPTResponseType.Auto)
    }, [version])

    useEffect(() => {
        if (inputQuestion) {
            sendQuestionInternal(inputQuestion)
        }
    }, [inputQuestion])

    const buildRequest = (question?: string, sessionId?: string): TGPTRequest => {
        const request: TGPTRequest = {
            PortalId: tModule.id,
            PortalName: tModule.name,
            CloudId: appInfo.cloudId,
            CloudName: appInfo.cloudName,
            AppId: appInfo.appId,
            AppName: appInfo.appName,
            UserId: userId,
            sessionId: sessionId ?? null,
            hasDocuments,
            accessKey,
            contextFilters,
            backupVersionId,
            scenarios,
        }

        if (question) {
            request.Question = question
        }

        return request
    }

    const getResponse = async (request: TGPTRequest): Promise<AIResponse> => {
        if (version === 'V1') {
            const response = await axios({
                method: 'post',
                url: `https://beta.tadanow.com/tadaaiv1/explain`,
                data: request,
            })
            return response.data
        } else {
            const response = await aiService.explain(request)
            return response
        }
    }

    const sendQuestion = async (value = inputValue, raw = null) => {
        sendQuestionInternal(value, raw)
    }

    const sendQuestionInternal = async (question, raw = null) => {
        if (!question.trim()) return
        setMessages([...messages, { text: question, type: 'user' }])
        setLoading(true)
        if (splitScreenOn) {
            setChatMessages([...chatMessages, { text: question, type: 'user' }])
            setChatLoading(true)
        }

        if (version === 'V3.1' && !hasDocuments) sendQuestionToChatAndAI(question, raw)
        else sendQuestionToAIAndChat(question, chatSessionId)
    }

    const reGenerateAIResponse = (question, summaryData) => {
        setLoading(true)

        getResponseSummary(question, summaryData)
    }

    const sendQuestionToAIAndChat = async (inputValue, chatSessionId) => {
        try {
            const request: TGPTRequest = buildRequest(inputValue, chatSessionId)
            const response: AIResponse = await getResponse(request)
            if (!response) {
                addBotMessage('Sorry, I did not understand that. Please try again.')
            } else {
                switch (response.responseType) {
                    case TGPTResponseType.Text:
                        addBotMessage(response.answer)
                        if (splitScreenOn) sendChatQuestion(response.answer)
                        break
                    default:
                        addBotMessage('Sorry, I did not understand that. Please try again.')
                        break
                }

                if (response.sessionId !== chatSessionId) {
                    dispatch(chatCreators.initiateAIChatSession(response.sessionId))
                }
            }
        } catch (err) {
            console.log(err)
            addBotMessage('An error occurred. Please try again.')
        } finally {
            setLoading(false)
            setInputValue('')
        }
    }

    const addBotMessage = async (text: any, showActions = true, messageType?: string, refData?: string) => {
        if (typeof text === 'string') {
            setMessages(prev => [
                ...prev,
                {
                    text: text,
                    type: 'bot',
                    showActions: showActions,
                    messageType: 'string',
                    refData: refData,
                },
            ])
            setShowStopGenerating(true)
        } else
            setMessages(prev => [
                ...prev,
                { text, type: 'bot', showActions: showActions, messageType: messageType, refData: refData },
            ])
    }

    const createSession = async () => {
        setLoading(true)
        try {
            const createSessionRequest: TGPTRequest = buildRequest()
            const response = await aiService.createSession(createSessionRequest)

            if (!response) throw new Error('Empty response or no session id')

            dispatch(chatCreators.initiateAIChatSession(response))
            return response
        } catch (e) {
            console.error(e)
            showSnackbar('There is an error uploading the file. Try again later.', { variant: 'error' })
            return null
        } finally {
            setLoading(false)
        }
    }

    const uploadFile = async (fileData, sessionId) => {
        setLoading(true)
        try {
            const response = await aiService.uploadFile(fileData, sessionId)

            if (!response) throw new Error('Empty response or no session id')

            setHasDocuments(true)
            showSnackbar('File has been uploaded successfully.', { variant: 'success' })
        } catch (e) {
            console.error(e)
            showSnackbar('There is an error uploading the file. Try again later.', { variant: 'error' })
        } finally {
            setLoading(false)
        }
    }

    const handleFileUpload = async e => {
        const sessionId = await createSession()
        if (sessionId) {
            const file = e.target.files[0]
            const fileData = new FormData()
            fileData.append('file', file)
            uploadFile(fileData, sessionId)
        }
    }

    const onNewChatClick = () => {
        setShowStopGenerating(false)
        dispatch(chatCreators.clearAllMessages())
        dispatch(chatCreators.clearAIChatSession())
        setMessages([
            {
                text: 'Hello, how can I help you?',
                type: 'bot',
            },
        ])
        setChatMessages([
            {
                text: 'Hello, how can I help you?',
                type: 'bot',
            },
        ])
    }

    const sendQuestionToChatAndAI = async (inputValue, raw = null) => {
        sendChatQuestion(inputValue, raw)
        setInputValue('')
    }

    const sendChatQuestion = async (question, raw = null) => {
        try {
            const request: TGPTRequest = {
                Question: question,
                PortalId: tModule.id,
                PortalName: tModule.name,
                CloudId: appInfo.cloudId,
                CloudName: appInfo.cloudName,
                AppId: appInfo.appId,
                AppName: appInfo.appName,
                UserId: userId,
                HidePerspectives: true,
                IsAIResponse: true,
                ResponseType: responseType,
                raw: raw,
            }
            const response: TGPTResponse =
                version === 'V3.1' && !hasDocuments
                    ? await chatService.getResponseV31(request)
                    : await chatService.getResponse(request)
            if (!response) {
                addChatBotMessage('Sorry, I did not understand that. Please try again.')
            } else {
                switch (response.ResponseType) {
                    case TGPTResponseType.Text:
                        addChatBotMessage(response.Answer)
                        setChatLoading(false)
                        break
                    case TGPTResponseType.Widget:
                        processWidgetResponse(response, request)
                        setChatLoading(false)
                        break
                    case TGPTResponseType.Data:
                        processDataResponse(response, question)
                        break
                    default:
                        addChatBotMessage('Sorry, I did not understand that. Please try again.')
                        setChatLoading(false)
                        break
                }
            }
        } catch (err) {
            console.log(err)
            addChatBotMessage('An error occurred. Please try again.')
        }
    }

    const addChatBotMessage = async (text: any, messageType?: string) => {
        if (typeof text === 'string')
            setChatMessages(prev => [...prev, { text: text, type: 'bot', messageType: 'string' }])
        else setChatMessages(prev => [...prev, { text, type: 'bot', messageType: messageType }])
    }

    function processWidgetResponse(response, request) {
        const widget = response.Item
        const widgets = response.Widgets
        const title = response.Answer

        if (!isEmpty(widgets)) {
            widgets.forEach(wid => {
                renderWidget(wid, title, request)
            })
        } else if (!isEmpty(widget)) {
            renderWidget(widget, title, request)
        } else {
            addChatBotMessage('Sorry, I did not understand that. Please try again.')
        }
    }

    function buildContext(filters, perspectiveId) {
        const [first, ...remaining] = filters
        const crumb = buildCrumb(first, perspectiveId)
        return { ...crumb, Filters: filters }
    }

    function buildCrumb(filter, perspectiveId) {
        return {
            Name: filter.ResourceName,
            FieldName: filter.PropertyName,
            Value: filter.Values.length === 1 ? filter.Values[0] : null,
            DefaultPerspective: perspectiveId,
        }
    }

    function renderWidget(widget, title, request) {
        if (widget.ContainerConfig) {
            addChatBotMessage(`Navigating to **${widget.Title ?? title}**`)
            const path = buildRoute(routePaths.PERSPECTIVE, request.AppName, request.PortalName, widget.Id)
            const context = widget.Filters ? buildContext(widget.Filters, widget.Id) : null
            setTimeout(() => {
                // dispatch(navigationCreators.goTo(routePaths.PERSPECTIVE, widget.Id))
                dispatch(navigationCreators.goToPath(path, { context }))
            }, 1500)
        } else {
            widget.Context = widget.Filters ? buildContext(widget.Filters, widget.Id) : null
            addChatBotMessage(`Here is what I found: **${widget.Title ?? title}**`)
            addChatBotMessage(widget, 'widget')
        }
    }

    function getSortedWidgetsByScore(widgetIdToName, widgetNameToScore) {
        const sortedWidgetNames = Object.entries(widgetNameToScore)
            .sort(([, scoreA], [, scoreB]) => Number(scoreB) - Number(scoreA))
            .map(([name]) => name)

        const matchingWidgets = sortedWidgetNames.reduce((result, name) => {
            for (const [id, widgetName] of Object.entries(widgetIdToName)) {
                if (widgetName === name) {
                    result[id] = name
                    break
                }
            }
            return result
        }, {})

        return matchingWidgets
    }

    async function processDataResponse(response, question) {
        const { Meta, SemanticData, Answer, WidgetMatches, WidgetScores } = response
        if (version === 'V3.1' && !hasDocuments) {
            await sendQuestionToSummarizer(question, Answer)
        }

        let hasResponse = false

        if (!isEmpty(SemanticData) && !isEmpty(Meta)) {
            Object.keys(SemanticData).forEach(key => {
                const insightMeta = Meta.find(m => m.Name === key)
                if (!isEmpty(insightMeta)) {
                    addChatBotMessage(<DataResponseTable {...insightMeta} />)
                }
            })
            hasResponse = true
        }

        if (!isEmpty(WidgetMatches) && !isEmpty(WidgetScores)) {
            const widgetsToShow = getSortedWidgetsByScore(WidgetMatches, WidgetScores)
            addChatBotMessage(<WidgetPills widgets={widgetsToShow} />)
            hasResponse = true
        }

        if (!hasResponse) addChatBotMessage('Sorry, I did not understand that. Please try again.')

        setChatLoading(false)
    }

    async function sendQuestionToSummarizer(question, answer) {
        if (!answer) {
            addBotMessage('Sorry, I did not understand that. Please try again.', false)
            setLoading(false)
            return
        }
        const semanticData = JSON.parse(answer)

        if (isEmpty(semanticData)) {
            addBotMessage('Sorry, I did not understand that. Please try again.', false)
            setLoading(false)
            return
        }
        const summaryData = {}
        for (const key in semanticData) {
            const value = semanticData[key]
            if (value?.Data?.length > 0) {
                summaryData[key] = value.Data
            }
        }

        await getResponseSummary(question, JSON.stringify(summaryData))
    }

    async function getResponseSummary(question, summaryData) {
        let request: TGPTRequest = buildRequest(question)
        request = { ...request, ...chatSettings }
        request.data = summaryData

        try {
            const response = await aiService.getSummary(request)

            if (!response) {
                addBotMessage('Sorry, I did not understand that. Please try again.', true, 'string', request.data)
                return
            }

            addBotMessage(response, true, 'string', request.data)
        } catch (err) {
            console.log(err)
            addBotMessage('An error occurred. Please try again.')
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <AIChatComponent
                ref={aiChatBoxRef}
                chatBoxRef={chatboxRef}
                Header={Header}
                messages={messages}
                loading={loading}
                inputValue={inputValue}
                setInputValue={setInputValue}
                sendQuestion={sendQuestion}
                enableFileUpload={true}
                handleFileUpload={handleFileUpload}
                splitScreenOn={splitScreenOn}
                chatMessages={chatMessages}
                chatLoading={chatLoading}
                onNewChatClick={onNewChatClick}
                scrollIntoView={scrollIntoView}
                scrollIntoChatView={scrollIntoChatView}
                showStopGenerating={showStopGenerating}
                setShowStopGenerating={setShowStopGenerating}
                onRegenerateResponse={reGenerateAIResponse}
            />
        </>
    )
}

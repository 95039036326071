import React, { Component } from 'react'
import { connect } from 'react-redux'
import Slick from 'react-slick'
import { filterCreators } from '../actions/creators'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Widget from '../components/widgets/Widget'

class FullScreenContainer extends Component {
    constructor(props) {
        super(props)
        this.widgetRef = React.createRef()
    }
    componentDidMount() {
        this.slider1.slickGoTo(this.props.goTo, true)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.goTo !== this.props.goTo) {
            this.slider1.slickGoTo(this.props.goTo)
        }
    }

    render() {
        const settings = {
            dots: false,
            infinite: false,
            arrows: false,
            swipe: false,
            waitForAnimate: false,
            beforeChange: (current, next) => this.props.setCurrentWidget(this.props.widgets[next].Id),
        }
        return (
            <Slick ref={slider => (this.slider1 = slider)} {...settings}>
                {this.props.widgets.map(config => {
                    return (
                        <div
                            className="widget-fullscreen"
                            ref={widgetContainer => (this.widgetContainer = widgetContainer)}
                            key={config.Id}
                        >
                            <Widget config={config} networkContext={this.props.networkContext} ref={this.widgetRef} />
                        </div>
                    )
                })}
            </Slick>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    setCurrentWidget: widgetId => dispatch(filterCreators.setCurrentWidgetId(widgetId)),
})

FullScreenContainer = connect(null, mapDispatchToProps)(FullScreenContainer)

export default FullScreenContainer

import { CSSProperties, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, CircularProgress, IconButton, List, ListItem, Paper, Popper, SxProps, Typography } from '@mui/material'
import { useDebouncedCallback } from 'use-debounce'

import { BareResource, PropertyMetaWithSemantic, ResourceType } from 'genesis-suite/types/networkTypes'
import { Aggregation, Basket, ChartType, DataResponse, MapConfig, MapSeries } from 'genesis-suite/types/visualTypes'
import { ChopText } from 'genesis-suite/components'
import { SideNavOpen as CollapseIcon } from 'genesis-suite/icons'
import Widget2 from '../Widget2'
import useProperties, { useResourcesWithLocationData } from '../../../hooks/useProperties'
import { modelService } from '../../../lib/services'
import { applicationSelectors } from '../../../selectors'
import { HoverMethod, InteractionProps } from '../../../types/WidgetTypes'
import { navigationCreators } from '../../../actions/creators'
import NodeLocationsTable from './NodeLocationsTable'
import noData from '../utils/noData'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { makeRows } from './utils'
import { useBrowserPreferences } from '../../../lib/browserPreferences'

const locationIdLabel = 'Tada Location ID'

interface LocationTooltip {
    loading?: boolean
    event?: PointerEvent
    data?: { label: string; value: any }[]
}

interface ResourceAndProps extends BareResource {
    properties: PropertyMetaWithSemantic[]
}

export default function LocationView({ className }) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const resourcesWithLocationData = useResourcesWithLocationData()
    const properties = useProperties()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    const [hideNodeMapGridLines, setHideNodemapGridLines] = useBrowserPreferences('hideNodeMapGridLines')
    const [tooltip, setTooltip] = useState<LocationTooltip>({})
    const [hide, setHide] = useState(false)
    const [nodeWidgetData, setNodeWidgetData] = useState<DataResponse>()
    const [isTableCollapsed, setIsTableCollapsed] = useState(false)

    const nodeTableWrapperStyles = createTableWrapperStyles(isMobile, isTableCollapsed)

    const nodes = resourcesWithLocationData.reduce((acc, cur) => {
        const nodeProperties = properties.filter(p => p.container.name === cur.name)
        if (cur.type !== ResourceType.NODE) return acc
        return [...acc, { ...cur, properties: nodeProperties }]
    }, [] as ResourceAndProps[])

    const cancelCall = useRef<any>(null)

    const handlePointHover = useDebouncedCallback(async (interaction: InteractionProps, method: HoverMethod) => {
        cancelCall.current?.()
        if (method === 'leave') return setTooltip({ loading: false })

        const { event, row, indexes } = interaction
        const node = nodes[indexes.series]
        const primaryPropertyName = node.properties.find(p => p.isPrimary)?.name
        const primaryPropertyValue = row[primaryPropertyName]
        setTooltip({ loading: true, event })

        try {
            const response = await modelService.getResourceValues(
                appName,
                node.name,
                { filters: `${primaryPropertyName}=${primaryPropertyValue}` },
                '',
                c => (cancelCall.current = c)
            )
            const firstRow = response?.values[0]
            const locationIdPropertyName = node.properties.find(p => p.semanticType.name === 'LocationId')?.name
            const data = firstRow
                ? Object.keys(firstRow)
                      .map(key => {
                          const property = node.properties.find(p => p.name === key)
                          const label =
                              property?.name === locationIdPropertyName ? locationIdLabel : property?.displayName
                          return { label, value: firstRow[key] }
                      })
                      .sort((a, b) => (a.label === locationIdLabel ? -1 : 1))
                : undefined
            setTooltip({ loading: false, event, data })
        } catch (error) {
            if (error.__CANCEL__) return
            console.error(error)
        } finally {
            cancelCall.current = null
        }
    }, 200)

    const nodeWidgetConfig: MapConfig = {
        appName,
        options: { fullscreenControl: false },
        type: ChartType.MAP,
        grid: { show: !hideNodeMapGridLines, toggle: true },
        series: nodes?.map((n): MapSeries => {
            return {
                clusterOptions: { gridSize: 50, minimumClusterSize: 5 },
                service: { type: n.type, name: n.name },
                size: 10,
                title: n.displayName || n.name,
                tooltip: { disabled: true },
                type: 'marker',
                values: createSeriesValues(n),
            }
        }),
    }

    const tableRows = useMemo(
        () => makeRows(nodeWidgetConfig?.series, nodeWidgetData),
        [nodeWidgetConfig, nodeWidgetData]
    )

    function handleData({ data }) {
        setNodeWidgetData(data)
        if (noData(data)) setHide(true)
    }

    if (!nodes?.length || hide) return null

    return (
        <div className={className}>
            {tableRows.length > 0 && (
                <Box sx={nodeTableWrapperStyles}>
                    <NodeLocationsTable config={nodeWidgetConfig} rows={tableRows} isCollapsed={isTableCollapsed} />
                    <NodeTableCollapseIcon
                        isMobile={isMobile}
                        isTableCollapsed={isTableCollapsed}
                        onClick={() => setIsTableCollapsed(prevState => !prevState)}
                    />
                </Box>
            )}
            <Widget2
                config={nodeWidgetConfig}
                customInteractions={{ onMapGridToggle: show => setHideNodemapGridLines(!show) }}
                onData={handleData}
                onStatus={s => s === 'error' && setHide(true)}
                interactions={{
                    onPointHover: handlePointHover,
                    onNavigate: (perspectiveId, filters) => {
                        dispatch(navigationCreators.goToDashboardWithFilters(perspectiveId, filters))
                    },
                }}
            />
            {Boolean(tooltip.event) && (
                <Popper open placement="right-start" anchorEl={tooltip.event.target as any}>
                    {tooltip.loading ? (
                        <Box p={2}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Paper sx={{ maxWidth: '400px' }}>
                            {tooltip.data ? (
                                <List dense>
                                    {tooltip.data.map(({ label, value }) => (
                                        <ListItem key={label} sx={{ gap: 1 }}>
                                            <Typography fontWeight="bold">{label}:</Typography>
                                            <ChopText showEllipsis>
                                                {value === true ? 'Yes' : value === false ? 'No' : value}
                                            </ChopText>
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Typography sx={{ p: 2 }}>No additional information</Typography>
                            )}
                        </Paper>
                    )}
                </Popper>
            )}
        </div>
    )
}

interface NodeTableCollapseIconProps {
    isMobile: boolean
    isTableCollapsed: boolean
    onClick: () => void
}

function NodeTableCollapseIcon({ isMobile, isTableCollapsed, onClick }: NodeTableCollapseIconProps) {
    const iconWrapperStyles = createCollapseIconStyles(isMobile, isTableCollapsed)

    return (
        <Box sx={iconWrapperStyles}>
            <IconButton sx={{ color: 'text.primary' }} size="small" onClick={onClick}>
                <CollapseIcon fontSize="small" />
            </IconButton>
        </Box>
    )
}

function createSeriesValues(node: ResourceAndProps): MapSeries['values'] {
    const id = node.properties.find(p => p.isPrimary)?.name
    const tadaLocId = node.properties.find(p => p.semanticType.name === 'LocationId')?.name
    const latitude = node.properties.find(p => p.semanticType.name === 'Latitude')?.name
    const longitude = node.properties.find(p => p.semanticType.name === 'Longitude')?.name
    const label = node.properties.find(p => p.semanticType.name.toLowerCase() === 'name')?.name

    const navigation = { enabled: true }

    return [
        ...(id ? [{ aggregation: Aggregation.NONE, basket: Basket.ID, field: { name: id }, navigation }] : []),
        ...(tadaLocId ? [{ aggregation: Aggregation.NONE, basket: Basket.ID2, field: { name: tadaLocId } }] : []),
        ...(latitude ? [{ aggregation: Aggregation.NONE, basket: Basket.LATITUDE, field: { name: latitude } }] : []),
        ...(longitude ? [{ aggregation: Aggregation.NONE, basket: Basket.LONGITUDE, field: { name: longitude } }] : []),
        ...(label ? [{ aggregation: Aggregation.NONE, basket: Basket.LABEL, field: { name: label } }] : []),
    ]
}

function createTableWrapperStyles(isMobile: boolean, isTableCollapsed: boolean): SxProps {
    const value = isTableCollapsed ? '0px' : isMobile ? '60%' : '30%'
    return { position: 'relative', [isMobile ? 'height' : 'width']: value }
}

function createCollapseIconStyles(isMobile: boolean, isTableCollapsed: boolean): SxProps {
    const positions = isMobile ? { bottom: '0%', left: '50%' } : { bottom: '5%', right: '5%' }
    let transform: CSSProperties['transform']

    if (isTableCollapsed) {
        transform = isMobile ? 'translate(0%, 100%) rotate(90deg)' : 'translate(100%, -50%) rotate(0deg)'
    } else {
        transform = isMobile ? 'translate(-50%, 0%) rotate(-90deg)' : 'translate(0, -50%) rotate(-180deg)'
    }

    return {
        position: 'absolute',
        zIndex: 1,
        backgroundColor: 'background.secondary',
        borderRadius: '0% 50% 50% 0%',
        padding: '4px',
        transform,
        ...positions,
    }
}

import { Box, Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DefaultErrorBoundary } from 'genesis-suite/components'
import { useUpdateEffect } from 'genesis-suite/hooks'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useMatch, useParams } from 'react-router-dom'
import 'tadacharts' // need to import all of tadacharts for chart-utils to work correctly
import { applicationCreators, moduleCreators, navigationCreators, authCreators } from '../actions/creators'
import AppTour from '../components/AppTour'
import MigrateModule from '../components/MigrateModule/MigrateModule'
import ModuleUpdate from '../components/ModuleUpdate'
import { CommentProvider } from '../components/comments/CommentContext'
import WidgetThumbnailMaker from '../components/widgets2/WidgetThumnailMaker'
import { appearanceConstants } from '../constants'
import { useIsMobile } from '../hooks/useIsMobile'
import { pageview } from '../lib/googleAnalyticsClient'
import { routePaths } from '../lib/routes'
import { appearanceSelectors, applicationSelectors, deploymentSelectors, moduleSelectors } from '../selectors'
import SideNav from './SideNav'
import RightNav from './rightnav/RightNav'
import AppViewRouter from './routers/AppViewRouter'
import LeftNav from '~/components/LeftNav'
import { userService } from '~/lib/services'

pageview(window.location.pathname + window.location.search)

const useStyles = makeStyles(({ palette, spacing }) => {
    return {
        loading: { margin: 'auto', maxWidth: '400px' },
        selectApplicationsButton: { display: 'block', margin: spacing(2), textAlign: 'center' },
        contentContainer: {
            flex: 1,
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
            backgroundColor: palette.background.main,
        },
    }
})

/**
 * Main view of the application
 * @param {object} props react props
 * @param {string} props.userId user id
 * @param {string} props.applicationName current application name
 */
export default function AppView() {
    const { appName, moduleName } = useParams()
    const isMobile = useIsMobile()
    const atManage = Boolean(useMatch(routePaths.MANAGE))

    const currentAppName = useSelector(applicationSelectors.getCurrentAppName)
    const appInfo = useSelector(applicationSelectors.getAppInfo)
    const applications = useSelector(applicationSelectors.getAllApplications)
    const moduleList = useSelector(moduleSelectors.getActiveModules)
    const currentModuleName = useSelector(moduleSelectors.getModuleName)
    const currentModuleViewFlag = useSelector(moduleSelectors.getViewFlag)
    const moduleError = useSelector(moduleSelectors.getModuleError)
    const sideNavOpen = useSelector(appearanceSelectors.getSideNavState)
    const rightNavOpen = Boolean(useSelector(appearanceSelectors.getRightNavTab))
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const hideAppContent = useSelector(appearanceSelectors.getHideAppContent)

    const dispatch = useDispatch()

    const classes = useStyles({
        sideNavOpen,
        rightNavOpen,
        isMobile,
        constants: appearanceConstants.RightNav,
    })

    const applicationError = !applications.includes(appName)
    const fetchModule = shouldChangeModule(currentModuleName, moduleName, currentModuleViewFlag, viewFlag)

    useEffect(() => {
        async function go() {
            if (applicationError) return
            if (appName === currentAppName) {
                dispatch(authCreators.checkActAs())
                const preferences = await userService.getUserPreferences(appName)
                dispatch(authCreators.updateUserPrefs(preferences))
            }
            if (appName !== currentAppName) dispatch(applicationCreators.selectApplication(appName))
            else if (!moduleList) dispatch(moduleCreators.getAppModules(appName, true))
            else if (!moduleName) dispatch(navigationCreators.goToModule())
            else if (fetchModule) dispatch(moduleCreators.changeModule(moduleName))
            else if (currentModuleName) dispatch(moduleCreators.appModuleChanged(isMobile))
        }
        go()
    }, [applicationError, appName, currentAppName, moduleName, currentModuleName, moduleList, fetchModule])

    useUpdateEffect(() => {
        dispatch(moduleCreators.getAppModules(appName, true))
    }, [viewFlag])

    const content = applicationError ? (
        <div className={classes.loading}>
            <Typography variant="h5" align="center">
                Cloud Error
            </Typography>
            <Typography align="center">
                There was an issue loading cloud <strong>{appName}</strong> from the url. If you're confident of the
                spelling, reach out to your account admin for access.
            </Typography>
            <Link to={routePaths.SELECT} className={classes.selectApplicationsButton}>
                <Button variant="outlined">Go to your clouds</Button>
            </Link>
        </div>
    ) : moduleError ? (
        <div className={classes.loading}>
            <Typography variant="h5" align="center">
                Application Error
            </Typography>
            <Typography align="center">{moduleError}</Typography>
        </div>
    ) : !moduleName || fetchModule ? (
        <Typography variant="h5" align="center" className={classes.loading}>
            Loading application...
        </Typography>
    ) : (
        <AppViewRouter />
    )

    return (
        <Box sx={{ flex: 1, display: 'flex', overflow: 'hidden' }} id="app-container">
            <CommentProvider>
                {!atManage && (
                    <DefaultErrorBoundary>
                        <SideNav />
                        <LeftNav />
                    </DefaultErrorBoundary>
                )}
                <div className={classes.contentContainer} style={{ display: hideAppContent ? 'none' : 'flex' }}>
                    <DefaultErrorBoundary>
                        {content}
                        <ModuleUpdate />
                    </DefaultErrorBoundary>
                </div>
                <DefaultErrorBoundary>
                    <RightNav />
                    <AppTour />
                    <MigrateModule />
                    <WidgetThumbnailMaker />
                </DefaultErrorBoundary>
            </CommentProvider>
        </Box>
    )
}

function shouldChangeModule(currentModuleName, urlName, currentModuleViewFlag, viewFlag) {
    if (!urlName) return false
    if (currentModuleName !== urlName) return true
    return currentModuleViewFlag != viewFlag
}

import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import MobileNavigation from '../components/MobileNavigation'
import SuspenseLoader from '../components/SuspenseLoader'
import TopNav from '../components/TopNav'
import ActAsSnackbar from '~/components/ActAsSnackbar'
import { subPaths } from '../lib/routes'
import { useSelector } from 'react-redux'
import { authSelectors } from '~/selectors'
import MessageBar from '~/components/Messenger/MessageBar'

const EditWidgetView = lazy(() => import('./EditWidgetView'))
const TVView = lazy(() => import('./TVView'))
const AppView = lazy(() => import('./AppView'))

const AppLayout = () => {
    const { PERSPECTIVE } = subPaths
    const actAsUser = useSelector(authSelectors.getActAs)

    const routes = [
        {
            path: 'edit/:widgetID',
            element: (
                <SuspenseLoader>
                    <EditWidgetView />
                </SuspenseLoader>
            ),
        },
        {
            path: `${PERSPECTIVE}/tv`,
            element: (
                <SuspenseLoader>
                    <TVView />
                </SuspenseLoader>
            ),
        },
        {
            path: '*',
            element: (
                <SuspenseLoader>
                    <AppView />
                </SuspenseLoader>
            ),
        },
    ]
    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                border: actAsUser ? '5px solid #069d8e' : '',
            }}
        >
            <TopNav />
            <Routes>
                {routes.map(props => (
                    <Route key={props.path} {...props} />
                ))}
            </Routes>
            {/* <MobileNavigation /> */}
            <ActAsSnackbar />
            <MessageBar />
        </div>
    )
}

export default AppLayout

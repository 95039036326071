import { chatTypes } from '../types'

const updateChatMessages = messages => ({
    type: chatTypes.UPDATE_CHAT_MESSAGES,
    payload: { messages },
})

const updateAIChatMessages = messages => ({
    type: chatTypes.UPDATE_AI_CHAT_MESSAGES,
    payload: { messages },
})

const toggleSplitScreen = () => ({
    type: chatTypes.TOGGLE_SPLIT_SCREEN,
})

const clearAllMessages = () => ({ type: chatTypes.CLEAR_ALL_MESSAGES })

const initiateAIChatSession = sessionId => {
    return {
        type: chatTypes.INITIATE_AI_CHAT_SESSION,
        payload: { sessionId },
    }
}

const clearAIChatSession = () => ({ type: chatTypes.CLEAR_AI_CHAT_SESSION })

const toggleChatDensity = value => ({ type: chatTypes.TOGGLE_CHAT_DENSITY, payload: { value } })

const updateChatSettings = settings => ({
    type: chatTypes.UPDATE_CHAT_SETTINGS,
    payload: { settings },
})

const setAIChatVersion = version => ({
    type: chatTypes.UPDATE_CHAT_VERSION,
    payload: { version },
})

const toggleShowSuggestions = () => ({
    type: chatTypes.TOGGLE_SHOW_SUGGESTIONS,
})

export const chatCreators = {
    updateChatMessages,
    updateAIChatMessages,
    toggleSplitScreen,
    clearAllMessages,
    initiateAIChatSession,
    clearAIChatSession,
    toggleChatDensity,
    updateChatSettings,
    setAIChatVersion,
    toggleShowSuggestions,
}

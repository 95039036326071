import { Tooltip, Chip, Stack, Popover, Box } from '@mui/material'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { widgetSelectors } from '~/selectors'
import Widget from '../../components/widgets/Widget'

export default function WidgetPills({ widgets }) {
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorOrigin, setAnchorOrigin] = useState({
        vertical: 'bottom',
        horizontal: 'center',
    })
    const [transformOrigin, setTransformOrigin] = useState({
        vertical: 'top',
        horizontal: 'center',
    })
    const widgetHeight = 300

    const [currentWidget, setCurrentWidget] = useState(null)
    const widgetConfigs = useSelector(widgetSelectors.getWidgetConfigs)

    const handlePopoverOpen = (event, id) => {
        const widgetConfig = widgetConfigs?.[id]
        const currentTarget = event.currentTarget
        if (widgetConfig && widgetConfig.Id !== currentWidget?.Id) {
            handlePopoverPos(event)
            setAnchorEl(currentTarget)
            setCurrentWidget(widgetConfig)
        }
    }

    const handlePopoverPos = event => {
        const button = event.currentTarget
        const rect = button.getBoundingClientRect()
        const isBottom = rect.bottom > window.innerHeight - widgetHeight

        if (isBottom) {
            setAnchorOrigin({ vertical: 'top', horizontal: 'center' })
            setTransformOrigin({ vertical: 'bottom', horizontal: 'center' })
        } else {
            setAnchorOrigin({ vertical: 'bottom', horizontal: 'center' })
            setTransformOrigin({ vertical: 'top', horizontal: 'center' })
        }
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
        setCurrentWidget(null)
    }

    return (
        <Stack direction="row" useFlexGap spacing={1} flexWrap="wrap" onMouseLeave={handlePopoverClose}>
            {Object.entries(widgets).map(([id, name]: [string, string]) => (
                <Chip
                    sx={{
                        maxWidth: '150px',
                        fontSize: '0.8rem',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        height: '40px',
                        borderRadius: '24px',
                        cursor: 'pointer',
                        color: '#fff',
                    }}
                    label={name}
                    variant="outlined"
                    onMouseEnter={e => handlePopoverOpen(e, id)}
                />
            ))}

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={JSON.parse(JSON.stringify(anchorOrigin))}
                transformOrigin={JSON.parse(JSON.stringify(transformOrigin))}
                disableAutoFocus
                slotProps={{
                    paper: {
                        onMouseEnter: () => {
                            // Do nothing
                        },
                        onMouseLeave: handlePopoverClose,
                    },
                }}
            >
                {currentWidget && (
                    <Box sx={{ width: '300px', height: widgetHeight + 'px', display: 'flex', alignItems: 'center' }}>
                        {/* @ts-ignore */}
                        <Widget config={currentWidget} />
                    </Box>
                )}
            </Popover>
        </Stack>
    )
}

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Tooltip } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import { SelectPopover } from 'genesis-suite/components'
import { useHover } from 'genesis-suite/hooks'
import { DrillDown, InfiniteNav, InlineFilter } from 'genesis-suite/icons'
import { widgetSelectors, moduleSelectors } from '../selectors'
import { widgetCreators } from '../actions/creators'
import { widgetConstants } from '../constants'
import { HotKeys, createTip } from '../lib/hotkeys'
import { useHotKey } from 'genesis-suite/hooks'

const { DRILLDOWN, INFINITE, INLINE } = widgetConstants.Interactions
export const options = [
    { value: INFINITE, Icon: InfiniteNav, label: 'Infinite navigation' },
    { value: DRILLDOWN, Icon: DrillDown, label: 'Drilldown' },
    // { value: INLINE, Icon: InlineFilter, label: 'Inline' },
]
const snackbarConfig = { variant: 'success', autoHideDuration: 2000 }

const useStyles = makeStyles(({ palette }) => ({
    interactionToggle: { position: 'relative' },
    tooltip: { background: palette.primary.main, color: palette.primary.contrastText },
    tooltipArrow: { color: palette.primary.main },
    tooltipRef: { position: 'absolute', right: '50%', bottom: '50%' },
}))

/**
 * Toggle interaction mode
 * @param {*=} props
 * @returns
 */
export default function InteractionToggle({ disabled, iconProps, buttonStyle }) {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const value = useSelector(widgetSelectors.getInteractionType)
    const loadedModule = Boolean(useSelector(moduleSelectors.getCurrentModule))

    const dispatch = useDispatch()

    const [showTooltip, setShowTooltip] = useState(false)

    const classes = useStyles()

    useEffect(() => {
        if (!loadedModule) return

        let timeout1, timeout2
        timeout1 = setTimeout(() => {
            setShowTooltip(true)
            timeout2 = setTimeout(() => {
                setShowTooltip(false)
            }, 3000)
        }, 1000)

        return () => {
            clearTimeout(timeout1)
            clearTimeout(timeout2)
        }
    }, [loadedModule, value])

    const handleChange = next => {
        if (next.value === value) return

        const label = options.find(o => o.value === next.value).label
        showSnackbar(`Switched to ${label}`, snackbarConfig)
        dispatch(widgetCreators.changeInteractionType(next.value))
    }

    useHotKey(HotKeys.Interaction, () => {
        const next = value === DRILLDOWN ? { value: INFINITE } : { value: DRILLDOWN }
        handleChange(next)
    })

    const keyTip = createTip(HotKeys.Interaction)

    return (
        <div className={classes.interactionToggle}>
            <SelectPopover
                PlaceHolderIcon={InfiniteNav}
                value={options.find(o => o.value === value)}
                tooltip={`${options.find(o => o.value === value).label} mode. >[${keyTip}] to switch`}
                items={options}
                iconProps={iconProps}
                buttonProps={{ sx: buttonStyle, 'data-cy': 'interaction' }}
                disabled={disabled}
                onChange={handleChange}
            />

            <Tooltip
                placement="bottom"
                arrow
                classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
                open={showTooltip}
                title={`${options.find(o => o.value === value).label} mode. >[${keyTip}] to switch`}
            >
                <span className={classes.tooltipRef} />
            </Tooltip>
        </div>
    )
}

import { Box, Button, Container, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Logout } from 'genesis-suite/icons'
import { useDispatch, useSelector } from 'react-redux'

import { buildRoute } from 'genesis-suite/utils'
import { authCreators, navigationCreators } from '../actions/creators'
import BrickGrid from '../components/BrickGrid'
import { logEvent } from '../lib/amplitudeClient'
import { getAppLogo } from '../lib/appLogoUtils'
import { routePaths } from '../lib/routes'
import useApplications from '~/hooks/useApplications'
import { userService } from '~/lib/services'
import { useIsMobile } from '~/hooks/useIsMobile'

const useStyles = makeStyles(({ spacing, palette, custom }) => ({
    topBar: {
        width: '100%',
        height: custom.topNav.height,
        background: palette.tada.purple,
        padding: spacing(0, 1),
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        justifyContent: 'space-between',
    },
    logo: { height: custom.topNav.height - 30, maxWidth: 230 },
    logoutBtnDiv: {
        width: '40%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
}))

export default function ApplicationSelectView() {
    const appLogo = getAppLogo()
    const classes = useStyles()
    const [applications] = useApplications()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    const selectApplication = async appName => {
        const preferences = await userService.getUserPreferences(appName)
        if (preferences?.length) {
            dispatch(authCreators.updateUserPrefs(preferences))
        }
        dispatch(navigationCreators.goToPath(buildRoute(routePaths.MODULE_SELECT, appName)))
    }

    function handleSignout() {
        logEvent('SIGN_OUT_FROM_APP_SELECT')
        dispatch(authCreators.logout())
    }

    if (!applications || !applications.length) return null

    if (applications.length === 1) selectApplication(applications[0].AppName)

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className={classes.topBar}>
                <div style={{ width: '60%', display: 'flex', justifyContent: isMobile ? 'flex-end' : 'flex-start' }}>
                    <img className={classes.logo} alt="App Logo" src={appLogo.light} draggable="false" />
                </div>
                <div className={classes.logoutBtnDiv}>
                    <Button
                        data-cy="logout-btn"
                        sx={{ color: '#fff', fontWeight: 'normal' }}
                        startIcon={<Logout />}
                        onClick={handleSignout}
                    >
                        Log out
                    </Button>
                </div>
            </div>

            <Box width="100%" overflow="auto">
                <Container maxWidth="lg">
                    <Typography variant="h5" align="center" sx={{ py: 6 }}>
                        Launch App
                    </Typography>

                    <BrickGrid
                        items={applications.map(a => ({ id: a.AppName, title: a.AppDisplayName || a.AppName }))}
                        onSelect={selectApplication}
                    />
                </Container>
            </Box>
        </Box>
    )
}

import { AddRounded, Person, SendRounded } from '@mui/icons-material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded'
import ReplayIcon from '@mui/icons-material/Replay'
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined'
import {
    Avatar,
    Box,
    Divider,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    OutlinedInput,
    Switch,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import { FileUploadButton, TadaGPTEditor } from 'genesis-suite/components'
import { ShortTadaLogoIcon, SingleScreen, SplitScreen } from 'genesis-suite/icons'
import { Dispatch, SetStateAction, forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import Measure from 'react-measure'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import useSearch from '~/hooks/useSearch'
import {
    getCaretCoordinates,
    getLastWordOfString,
    insertHtmlAtCaret,
    parseHTMLToArray,
    stripHtmlDOM,
} from '~/lib/utils'
import { chatSelectors } from '~/selectors'
import { chatCreators } from '../../actions/creators'
import AIRightChatComponent from './AIRightChatComponent'
import AiSettings from './AISettings'
import AIVersionToggle from './AIVersionToggle'
import ChatAutoComplete from './ChatAutoComplete'
import ChatDensityToggle from './ChatDensityToggle'
import ResponseFormatToggle from './ResponseFormatToggle'
import SpeechMimic from './SpeechMimic'

type Message = {
    text: string | JSX.Element
    type: 'user' | 'bot'
}

interface ChatComponentProps {
    Header: any
    messages: any[]
    loading: boolean
    sendQuestion: (value?: string, raw?: any) => void
    inputValue: string
    setInputValue: (value: string) => void
    enableFileUpload?: boolean
    handleFileUpload?: (e: any) => void
    showStopGenerating?: boolean
    setShowStopGenerating?: Dispatch<SetStateAction<boolean>>
    splitScreenOn?: boolean
    chatBoxRef?: any
    chatMessages: Message[]
    chatLoading: boolean
    onNewChatClick: () => void
    scrollIntoView: () => void
    scrollIntoChatView: () => void
    onRegenerateResponse?: (question: string, summaryData: string) => void
}

const AIChatComponent = forwardRef(
    (
        {
            Header,
            messages,
            loading,
            sendQuestion,
            inputValue,
            setInputValue,
            enableFileUpload,
            handleFileUpload,
            showStopGenerating,
            setShowStopGenerating,
            splitScreenOn = false,
            chatBoxRef,
            chatMessages,
            chatLoading,
            onNewChatClick,
            scrollIntoView,
            scrollIntoChatView,
            onRegenerateResponse,
        }: ChatComponentProps,
        ref
    ) => {
        const [reGenerate, setReGenerate] = useState(false)
        const dispatch = useDispatch()
        const [height, setHeight] = useState('70vh')
        const chatDensity = useSelector(chatSelectors.getChatDensity)
        const version = useSelector(chatSelectors.getAIChatVersion)

        const mimicApi = useRef(null)
        const editorRef = useRef(null)
        const parentRef = useRef<HTMLDivElement>(null)
        const { palette } = useTheme()
        const defaultIconProps = { style: { fontSize: '18px', color: palette.text.primary } }

        const [searchQuery, setSearchQuery] = useState('')
        const [suggestions, fetching] = useSearch(searchQuery, undefined, 30)
        const [anchorPos, setAnchorPos] = useState(null)
        const [editorHeight, setEditorHeight] = useState<number>(51)
        const showSuggestions = useSelector(chatSelectors.getShowSuggestions)
        const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1)
        const [openAutoComplete, setOpenAutoComplete] = useState(false)

        const handleChangeDebounce = useDebouncedCallback(handlePerformSearch, 900)

        const toggleShowSuggestions = () => {
            dispatch(chatCreators.toggleShowSuggestions())
        }

        function handlePerformSearch(query) {
            if (!query) return
            setAnchorPos(getCaretCoordinates(editorHeight))
            const striped = stripHtmlDOM(query)
            const lastValue = getLastWordOfString(striped)
            setSearchQuery(lastValue)
        }

        useEffect(() => {
            if (suggestions?.length) {
                setOpenAutoComplete(true)
            }
        }, [suggestions])

        useEffect(() => {
            if (reGenerate) {
                onStopGenerating()
                sendQuestion()
                setReGenerate(false)
            }
        }, [reGenerate])

        const onArrowDown = useCallback(() => {
            if (suggestions?.length) {
                setSelectedSuggestionIndex(prevIndex => (prevIndex + 1) % suggestions.length)
            }
        }, [suggestions])

        const onArrowUp = useCallback(() => {
            if (suggestions?.length) {
                setSelectedSuggestionIndex(prevIndex => (prevIndex - 1 + suggestions.length) % suggestions.length)
            }
        }, [suggestions])

        const onEnter = useCallback(() => {
            if (
                openAutoComplete &&
                suggestions?.length &&
                selectedSuggestionIndex > -1 &&
                suggestions[selectedSuggestionIndex]
            ) {
                onClickMenuItem(suggestions[selectedSuggestionIndex])
            }
        }, [suggestions, selectedSuggestionIndex, openAutoComplete])

        const onEscape = useCallback(() => handleClose(), [])

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value)
        }

        const handleGPTChange = (value: string) => {
            setInputValue(value)
            if (showSuggestions && value) {
                handleChangeDebounce(value)
            }
        }

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                onStopGenerating()
                sendQuestion()
            }
        }

        const onSendClick = () => {
            onStopGenerating()
            const editor = editorRef.current
            if (editor) {
                const htmlContent = editor.getHtml()
                const stripedVal = stripHtmlDOM(htmlContent)
                const raw = parseHTMLToArray(htmlContent)

                sendQuestion(stripedVal, raw)
            } else {
                sendQuestion()
            }
        }

        const onClickMenuItem = suggestion => {
            insertHtmlAtCaret(suggestion)
            updateEditorValue()
            handleClose()
        }

        const onHandleSubmit = () => {
            if (!openAutoComplete) {
                onSendClick()
            }
        }

        const updateEditorValue = () => {
            const editorInstance = editorRef.current
            if (editorInstance) {
                const val = editorInstance.getHtml()
                setInputValue(val)
            }
        }

        const onReGenerateClick = (index: number) => {
            if (index > 1) {
                if (version === 'V3.1') {
                    const message = messages[index]
                    const question = messages[index - 1].text
                    onRegenerateResponse(question, message.refData)
                    return
                }
                const message = messages[index - 1]
                if (message?.type === 'user') {
                    setInputValue(message.text)
                    setReGenerate(true)
                }
            }
        }

        const handleClose = () => {
            setSelectedSuggestionIndex(-1)
            setOpenAutoComplete(false)
        }

        const handleCopy = (text: any) => {
            navigator.clipboard.writeText(text)
        }

        const onUserMessageClick = (message: any) => {
            if (message.type !== 'user') return
            setInputValue(message.text)
        }

        const onTypingComplete = () => {
            scrollIntoView()
            setShowStopGenerating(false)
        }

        const onStopGenerating = () => {
            mimicApi?.current?.stopTyping()
            setShowStopGenerating(false)
        }

        const handleResize = () => {
            const { children, clientHeight } = parentRef.current
            if (!children.length) setHeight('70vh')

            const heights = [...children].map(child => child.clientHeight)
            setHeight(clientHeight - heights[0] + 'px')
        }

        const handleRepeat = (text: string) => {
            setInputValue(text)
            setReGenerate(true)
        }

        return (
            <Measure client innerRef={parentRef} onResize={handleResize}>
                {({ measureRef }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                        }}
                        ref={measureRef}
                    >
                        <Header>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1, maxHeight: '35px' }}>
                                <Tooltip title="Show suggestions">
                                    <Switch
                                        color="primary"
                                        checked={showSuggestions}
                                        onChange={toggleShowSuggestions}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </Tooltip>
                                {version === 'V3.1' && <AiSettings disabled={false} iconProps={defaultIconProps} />}
                                <AIVersionToggle disabled={false} iconProps={defaultIconProps} />
                                <ChatDensityToggle disabled={false} iconProps={defaultIconProps} />
                                <ResponseFormatToggle disabled={false} iconProps={defaultIconProps} />
                                <Tooltip title="New Chat">
                                    <IconButton color="inherit" onClick={onNewChatClick}>
                                        <AddRounded fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <IconButton
                                    size="large"
                                    sx={{ color: 'text.primary' }}
                                    onClick={() => dispatch(chatCreators.toggleSplitScreen())}
                                >
                                    {splitScreenOn ? (
                                        <SingleScreen fontSize="small" />
                                    ) : (
                                        <SplitScreen fontSize="small" />
                                    )}
                                </IconButton>
                            </Box>
                        </Header>
                        <Box key="chat-area" sx={{ display: 'flex', height: `${height}` }}>
                            <Box
                                key="ai-chat-box"
                                sx={{ display: 'flex', flexDirection: 'column', width: splitScreenOn ? '35%' : '100%' }}
                            >
                                <Box ref={ref} sx={{ flexGrow: 1, overflow: 'auto', p: 1 }}>
                                    <List sx={{ p: 0 }}>
                                        {messages.map((message, index) => (
                                            <>
                                                <ListItem
                                                    key={index}
                                                    sx={{
                                                        justifyContent:
                                                            message.type === 'user' && chatDensity !== 'compact'
                                                                ? 'flex-end'
                                                                : 'flex-start',
                                                        '& .fade-in': {
                                                            opacity: 0,
                                                            transition: 'opacity ease .2s',
                                                        },
                                                        '&:hover .fade-in': { opacity: 1 },
                                                    }}
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            sx={{
                                                                bgcolor:
                                                                    message.type === 'user'
                                                                        ? 'tada.teal'
                                                                        : 'tada.purple',
                                                                '& *':
                                                                    message.type === 'user'
                                                                        ? { color: '#000' }
                                                                        : { fill: '#fff !important' },
                                                            }}
                                                        >
                                                            {message.type === 'user' ? (
                                                                <Person />
                                                            ) : (
                                                                <ShortTadaLogoIcon />
                                                            )}
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={
                                                            message.messageType === 'string' ? (
                                                                <SpeechMimic
                                                                    text={message.text}
                                                                    onChange={scrollIntoView}
                                                                    onTypingComplete={onTypingComplete}
                                                                    onLoad={api => (mimicApi.current = api)}
                                                                    avoidTyping={message.avoidTyping}
                                                                />
                                                            ) : (
                                                                message.text
                                                            )
                                                        }
                                                        sx={{
                                                            bgcolor:
                                                                message.type === 'user' ? 'tada.teal' : 'tada.purple',
                                                            color: message.type === 'user' ? '#000' : '#fff',
                                                            borderRadius: 1,
                                                            p: 1,
                                                            minWidth: 100,
                                                            maxWidth: '80%',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => onUserMessageClick(message)}
                                                    />
                                                    {index > 0 && message.type === 'bot' && message.showActions && (
                                                        <Box sx={{ display: 'flex', gap: 0 }}>
                                                            <Tooltip title="Copy" className="fade-in">
                                                                <IconButton
                                                                    sx={{ color: 'text.disabled' }}
                                                                    component="label"
                                                                    onClick={() => handleCopy(message.text)}
                                                                >
                                                                    <ContentPasteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Re Generate" className="fade-in">
                                                                <IconButton
                                                                    sx={{ color: 'text.disabled' }}
                                                                    component="label"
                                                                    onClick={() => onReGenerateClick(index)}
                                                                >
                                                                    <ReplayIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    )}
                                                    {index > 0 && message.type === 'user' && (
                                                        <Tooltip title="Repeat question" className="fade-in">
                                                            <IconButton
                                                                sx={{ color: 'text.disabled' }}
                                                                component="label"
                                                                onClick={() => handleRepeat(message.text)}
                                                            >
                                                                <RepeatRoundedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </ListItem>
                                            </>
                                        ))}
                                        {loading && (
                                            <ListItem sx={{ justifyContent: 'center' }}>
                                                <FallingDots />
                                            </ListItem>
                                        )}
                                    </List>
                                </Box>
                                <Box
                                    sx={{
                                        p: 2,
                                        pb: 0.25,
                                        flex: 0,
                                        mt: 'auto',
                                        minWidth: 300,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {showSuggestions ? (
                                        <TadaGPTEditor
                                            editorHeight={editorHeight}
                                            setEditorHeight={setEditorHeight}
                                            editorRef={editorRef}
                                            value={inputValue}
                                            placeholder="How can I help you?"
                                            onChange={handleGPTChange}
                                            onHandleSubmit={onHandleSubmit}
                                            onArrowDown={onArrowDown}
                                            onArrowUp={onArrowUp}
                                            onEnter={onEnter}
                                            onEscape={onEscape}
                                            suggestions={suggestions}
                                            selectedSuggestionIndex={selectedSuggestionIndex}
                                            endAdornment={
                                                enableFileUpload ? (
                                                    <FileUploadButton
                                                        icon={<AttachFileIcon />}
                                                        tooltip="Attach File"
                                                        buttonStyle={{ ml: 1 }}
                                                        onFileUpload={handleFileUpload}
                                                    />
                                                ) : null
                                            }
                                        />
                                    ) : (
                                        <OutlinedInput
                                            autoFocus
                                            fullWidth
                                            value={inputValue}
                                            onChange={handleChange}
                                            onKeyPress={handleKeyDown}
                                            placeholder="How can I help you?"
                                            sx={{ pr: 0 }}
                                            endAdornment={
                                                enableFileUpload ? (
                                                    <InputAdornment position="end" sx={{ color: 'inherit' }}>
                                                        <FileUploadButton
                                                            icon={<AttachFileIcon />}
                                                            tooltip="Attach File"
                                                            buttonStyle={{ ml: 1 }}
                                                            onFileUpload={handleFileUpload}
                                                        />
                                                    </InputAdornment>
                                                ) : null
                                            }
                                        />
                                    )}

                                    <Tooltip title={showStopGenerating ? 'Stop Generating...' : 'Send'}>
                                        <IconButton
                                            color="inherit"
                                            sx={{ ml: 1, mb: 1 }}
                                            onClick={() => (showStopGenerating ? onStopGenerating() : onSendClick())}
                                        >
                                            {showStopGenerating ? <StopCircleOutlinedIcon /> : <SendRounded />}
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <ChatAutoComplete
                                    anchorPos={anchorPos}
                                    openAutoComplete={openAutoComplete}
                                    handleClose={handleClose}
                                    onClickMenuItem={onClickMenuItem}
                                    suggestions={suggestions}
                                    selectedSuggestionIndex={selectedSuggestionIndex}
                                />
                                <Typography sx={{ px: 2 }} variant="caption">
                                    Response generated by TADA explainable artificial intelligence may contain errors or
                                    inaccurate information for your questions and should not be relied upon as a
                                    substitute for professional advice. Validate your response accordingly.
                                </Typography>
                            </Box>
                            {splitScreenOn && (
                                <Box sx={{ display: 'flex', height: '100%', width: '65%' }}>
                                    <Divider
                                        flexItem
                                        sx={{ m: 1, borderColor: 'text.primary', height: '98%' }}
                                        orientation="vertical"
                                    />
                                    <Box key="chat-box" sx={{ flexGrow: 1 }}>
                                        <AIRightChatComponent
                                            Header={null}
                                            messages={chatMessages}
                                            loading={chatLoading}
                                            inputValue={inputValue}
                                            setInputValue={setInputValue}
                                            sendQuestion={sendQuestion}
                                            hideInput={true}
                                            ref={chatBoxRef}
                                            scrollIntoView={scrollIntoChatView}
                                        />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
            </Measure>
        )
    }
)

const FallingDots = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: 50 }}>
        <Box
            sx={{
                position: 'relative',
                left: '-9999px',
                width: '10px',
                height: '10px',
                borderRadius: '5px',
                backgroundColor: '#9880ff',
                color: '#9880ff',
                boxShadow: '9999px 0 0 0 #9880ff',
                animation: 'dot-falling 1s infinite linear',
                animationDelay: '0.1s',
                '&::before, &::after': {
                    content: '""',
                    display: 'inline-block',
                    position: 'absolute',
                    top: '0',
                },
                '&::before': {
                    width: '10px',
                    height: '10px',
                    borderRadius: '5px',
                    backgroundColor: '#9880ff',
                    color: '#9880ff',
                    animation: 'dot-falling-before 1s infinite linear',
                    animationDelay: '0s',
                },
                '&::after': {
                    width: '10px',
                    height: '10px',
                    borderRadius: '5px',
                    backgroundColor: '#9880ff',
                    color: '#9880ff',
                    animation: 'dot-falling-after 1s infinite linear',
                    animationDelay: '0.2s',
                },
                '@keyframes dot-falling': {
                    '0%': {
                        boxShadow: '9999px -15px 0 0 rgba(152, 128, 255, 0)',
                    },
                    '25%, 50%, 75%': {
                        boxShadow: '9999px 0 0 0 #9880ff',
                    },
                    '100%': {
                        boxShadow: '9999px 15px 0 0 rgba(152, 128, 255, 0)',
                    },
                },
                '@keyframes dot-falling-before': {
                    '0%': {
                        boxShadow: '9984px -15px 0 0 rgba(152, 128, 255, 0)',
                    },
                    '25%, 50%, 75%': {
                        boxShadow: '9984px 0 0 0 #9880ff',
                    },
                    '100%': {
                        boxShadow: '9984px 15px 0 0 rgba(152, 128, 255, 0)',
                    },
                },
                '@keyframes dot-falling-after': {
                    '0%': {
                        boxShadow: '10014px -15px 0 0 rgba(152, 128, 255, 0)',
                    },
                    '25%, 50%, 75%': {
                        boxShadow: '10014px 0 0 0 #9880ff',
                    },
                    '100%': {
                        boxShadow: '10014px 15px 0 0 rgba(152, 128, 255, 0)',
                    },
                },
            }}
        />
    </Box>
)

export default AIChatComponent

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  integration,
  sso
}) => generator({
  checkPassword: data => ({
    baseURL: integration,
    method: 'post',
    url: `security/check`,
    data
  }),
  ping: () => ({
    baseURL: integration,
    method: 'get',
    url: `security/ping`
  }),
  verifyUsername: username => ({
    baseURL: integration,
    method: 'post',
    url: `security/passwordreset`,
    config: {
      params: {
        username
      }
    }
  }),
  verifyOTP: (username, token, passcode) => ({
    baseURL: integration,
    method: 'post',
    url: `security/verifyuserotp`,
    config: {
      params: {
        username,
        token,
        passcode
      }
    }
  }),
  verifyNewPassword: (username, token, password) => ({
    baseURL: integration,
    method: 'post',
    url: `security/verifyuserpassword`,
    config: {
      params: {
        username,
        token,
        password
      }
    }
  }),
  savePassword: (token, data) => ({
    baseURL: integration,
    method: 'put',
    url: `security/savepassword`,
    config: {
      params: {
        token
      }
    },
    data
  }),
  ActAs: params => ({
    baseURL: integration,
    method: 'get',
    url: `security/ActAs`,
    config: {
      params: params
    }
  }),
  DeActAs: params => ({
    baseURL: integration,
    method: 'get',
    url: `security/DeActAs`,
    config: {
      params: params
    }
  }),
  getToken: () => ({
    baseURL: sso,
    method: 'get',
    url: `API/tokens`
  })
});
exports.default = _default;
import { Box, Card, CardContent, Typography, Grid, CircularProgress } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { applicationSelectors, moduleSelectors, widgetSelectors } from '~/selectors'
import { buildRoute } from 'genesis-suite/utils'
import { makeStyles } from '@mui/styles'
import { routePaths } from '~/lib/routes'
import { buildContext } from '~/views/NotificationView'
import { navigationCreators } from '~/actions/creators'
import { useIsMobile } from '~/hooks/useIsMobile'
import MobileWidgetRecommendations from './MobileWidgetRecommendations'
import { widgetService } from '~/lib/services'
import { getBaseUrl } from '~/lib/utils'

const useStyles = makeStyles(theme => ({
    widget: {
        height: '100%',
        borderRadius: '8px',
        flex: 'none',
    },
    recommendations: props => ({
        backgroundColor: theme.palette.background.main,
        border: `1px solid ${theme.palette.border?.main}`,
        borderRadius: '15px',
        padding: theme.spacing(2),
        height: props.isMobile || props?.isNoData ? '100%' : '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    }),
    cardGridContainer: {
        flex: 1,
        overflowY: 'auto',
        padding: theme.spacing(2),
    },
    cardContainer: {
        position: 'relative',
        height: '150px',
        borderRadius: '8px',
        transition: 'transform 0.3s ease-in-out',
    },
    cardContainerHover: {
        '&:hover': {
            transform: 'scale(1.10)',
            zIndex: 2,
        },
    },
    card: {
        position: 'relative',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        overflow: 'hidden',
        borderRadius: '20px',
        border: `1px solid ${theme.palette.border?.main}`,
        boxShadow: '2px 2px 5px 1px',
        backgroundColor: '#672771',
    },
    cardHover: {
        '&:hover': {
            borderRadius: '20px',
        },
    },
    imageBox: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    content: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontSize: '0.875rem',
        textAlign: 'center',
        // padding: theme.spacing(1),
        textTransform: 'uppercase',
        maxWidth: '75%',
        alignSelf: 'center',
        margin: 'auto',
    },
    popOutOverlay: {
        position: 'absolute',
        top: '100%',
        left: 0,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        color: 'white',
        padding: theme.spacing(1),
        borderRadius: '0 0 8px 8px',
        display: 'none',
        zIndex: 10,
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    },
    titleText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        fontSize: '1.25rem',
        lineHeight: '1.25',
        fontWeight: '700',
        textAlign: 'left',
        color: '#FFF',
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    noDataMessage: {
        textAlign: 'center',
        padding: theme.spacing(2),
    },
    ribbon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        backgroundColor: theme.palette.text.primary,
        position: 'absolute',
        top: '-7px',
        right: 0,
        height: '20px',
        overflow: 'hidden',
        zIndex: 1,
        padding: '8px',
    },
}))

const WidgetRecommendations = () => {
    const dispatch = useDispatch()
    const configs = useSelector(widgetSelectors.getWidgetConfigs)
    const tModule = useSelector(moduleSelectors.getCurrentModule)
    const appInfo = useSelector(applicationSelectors.getAppInfo)
    const widgetIds = useSelector(widgetSelectors.getRecommendedWidgets).widgets

    const [filteredConfig, setFilteredConfig] = useState([])
    const [loading, setLoading] = useState(true)

    const isMobile = useIsMobile()
    const classes = useStyles({ isMobile, isNoData: filteredConfig.length === 0 })

    useEffect(() => {
        const configsArray = Object.entries(configs)?.map(([key, value]) => ({ Id: key, ...value }))
        const matchingConfigs = configsArray?.filter(config => widgetIds.includes(config.Id))
        setFilteredConfig(matchingConfigs || [])
        setLoading(false)
    }, [widgetIds])
    const handleOpenWidget = widget => {
        const path = buildRoute(routePaths.PERSPECTIVE, appInfo.appName, tModule.name, widget.Id)
        const context = widget?.Filters ? buildContext(widget.Filters, widget.Id) : null
        dispatch(navigationCreators.goToPath(path, { context }))
    }

    if (filteredConfig.length === 0 && !loading) return null

    return (
        <div className={classes.widget}>
            <Box className={classes.recommendations}>
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                    Recommended Widgets {filteredConfig.length > 0 && `(${filteredConfig.length})`}
                </Typography>
                {loading ? (
                    <div className={classes.loaderContainer}>
                        <CircularProgress />
                    </div>
                ) : filteredConfig.length === 0 ? (
                    <Typography className={classes.noDataMessage} variant="h6">
                        No widget recommendations available.
                    </Typography>
                ) : (
                    <div className={classes.cardGridContainer}>
                        {isMobile ? (
                            <MobileWidgetRecommendations
                                items={filteredConfig}
                                handleOpenWidget={handleOpenWidget}
                                ThumbnailCard={ThumbnailCard}
                            />
                        ) : (
                            <Grid container spacing={2}>
                                {filteredConfig?.map((config, index) => (
                                    <Grid item md={2} lg={2} key={index}>
                                        <ThumbnailCard config={config} handleOpenWidget={handleOpenWidget} />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </div>
                )}
            </Box>
        </div>
    )
}
const ThumbnailCard = ({ config, handleOpenWidget }) => {
    const classes = useStyles()
    const isMobile = useIsMobile()

    const containerClassName = `${classes.cardContainer} ${!isMobile ? classes.cardContainerHover : ''}`
    const cardClassName = `${classes.card} ${!isMobile ? classes.cardHover : ''}`

    const getWidgetType = config => {
        if (config?.ContainerConfig) return 'Perspective'
        switch (config?.Type.toUpperCase()) {
            case 'CHART':
                if (config?.ChartConfig?.ChartType?.toUpperCase() === 'COLUMN') {
                    return 'Bar Chart'
                }

                return config?.ChartConfig?.ChartType ? config?.ChartConfig?.ChartType + ' Chart' : 'Chart'
            case 'GANTT':
                return 'Gantt Chart'
            case 'TREEGRAPH':
                return 'Tree Graph'
            case 'PNL':
                if (config?.PnLConfig?.LoadDynamically) {
                    return 'Group Table'
                }
                return 'PnL'
            case 'RICHTEXT':
                return 'Text'
            case 'STATUSTRACKER':
                return 'Status Tracker'
            case 'REPORTWIDGET':
                return 'Report'
            default:
                return config?.Type ? config?.Type : 'Widget'
        }
    }

    return (
        <div className={containerClassName} onClick={() => handleOpenWidget(config)}>
            <Card className={cardClassName} sx={{ boxShadow: isMobile && 'unset' }}>
                <Box className={classes.imageBox}></Box>
                <CardContent className={classes.content}>
                    <Typography variant="subtitle1" className={classes.titleText}>
                        {config?.Title}
                    </Typography>
                </CardContent>
            </Card>
            <Box className={classes.ribbon}>
                <Typography variant="subtitle1" sx={{ color: 'background.main' }}>
                    {getWidgetType(config)}
                </Typography>
            </Box>
        </div>
    )
}

export default WidgetRecommendations

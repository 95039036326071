import { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import classNames from 'classnames'
import moment from 'moment'

import makeStyles from '@mui/styles/makeStyles'
import { CloseRounded, ArrowDownwardRounded } from '@mui/icons-material'
import { Avatar, Badge, Box, Divider, Fab, IconButton, Typography } from '@mui/material'

import { Spinner, TextEditorV2 } from 'genesis-suite/components'
import { messageService, userService } from '~/lib/services'
import { messengerSelectors } from '~/selectors/messenger.selectors'
import { messengerCreators } from '~/actions/creators/messenger.creators'
import { ChatMessagesRequest, UsersRequest, Message } from 'genesis-suite/types/visualTypes'
import {
    applicationSelectors,
    authSelectors,
    collaborationSelectors,
    moduleSelectors,
    widgetSelectors,
} from '~/selectors'

import Markdown from '../Markdown'

const useStyles = makeStyles(({ palette }) => ({
    chatContent: {
        color: '#000',
    },
    messageList: {
        overflow: 'scroll',
        maxHeight: '340px',
        minHeight: '100px',
    },
    messageBox: {
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        position: 'absolute',
        bottom: '5px',
        width: '95%',
    },
    chatBubble: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '10px',
        marginBottom: '8px',
        width: 'max-content',
        maxWidth: 'calc(100% - 50px)',
        marginLeft: '5px',
        borderRadius: '0px 10px 10px 10px',
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        '& .mention': {
            fontWeight: 'bold',
        },
        '& p': {
            margin: 0,
        },
        '& pre': {
            margin: 0,
            whiteSpace: 'normal',
        },
    },
    chatBubbleRight: {
        marginLeft: 'auto',
        marginRight: '5px',
        borderRadius: '10px 0 10px 10px',
        backgroundColor: palette.secondary.main,
        color: palette.secondary.contrastText,
    },
    chatDate: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    chatDateRight: {
        justifyContent: 'flex-end',
    },
    chatUnread: {
        color: 'red',
        '&::before, &::after': {
            borderTop: '1px solid',
        },
    },
}))

const ChatBox = ({ classes, userData, onClose }) => {
    const chatClasses = useStyles()
    const chatRef = useRef(null)
    const dispatch = useDispatch()

    const appInfo = useSelector(applicationSelectors.getAppInfo)
    const tModule = useSelector(moduleSelectors.getCurrentModule)
    const chatList = useSelector(messengerSelectors.getMessageList)
    const { domainId, userId } = useSelector(authSelectors.getUser)
    const { cloudId, appId, partitionId: modelPartId, appName } = appInfo
    const appDomainId = useSelector(state => {
        const authAppInfo = applicationSelectors.getAuthorizedAppInfo(state, appName)
        return authAppInfo ? authAppInfo.domainId : undefined
    })
    const messages = useSelector(messengerSelectors.getChatByUserId(userData?.id))
    const users = useSelector(collaborationSelectors.collaborators)
    const isOnline = users.some(user => user.name === userData?.ScreenAlias)

    const [unReadCount, setUnReadCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [showScroll, setShowScroll] = useState(false)
    const [pagination, setPagination] = useState({
        pageNo: 1,
        pageSize: 10,
        hasMore: true,
    })

    const [isOpen, setIsOpen] = useState(true)
    const [openClass, setOpenClass] = useState(true)

    const getNewChat = useDebouncedCallback(() => getChatMessages(), 500)

    const scrollToBottom = useDebouncedCallback((snap = false) => {
        chatRef.current.scrollTo({
            top: chatRef.current.scrollHeight,
            behavior: snap ? 'auto' : 'smooth',
        })
    }, 200)

    const checkScroll = () => {
        if (chatRef.current) {
            // To show Scroll to Bottom FAB
            const { scrollTop, scrollHeight, clientHeight } = chatRef.current
            if (scrollTop < scrollHeight - clientHeight - 200) {
                setShowScroll(true)
            } else {
                setShowScroll(false)
            }

            if (scrollTop <= 100) {
                getNewChat()
            }
        }
    }

    useEffect(() => {
        if (messages.length > 0 && !showScroll && chatRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = chatRef.current
            if (scrollTop < scrollHeight - clientHeight) {
                setShowScroll(true)
            }
        }
    }, [messages])

    useEffect(() => {
        setTimeout(() => setOpenClass(false), 700)
        getChatMessages(true)

        if (chatRef.current) {
            chatRef.current.addEventListener('scroll', checkScroll)
        }
        return () => {
            if (chatRef.current) {
                chatRef.current.removeEventListener('scroll', checkScroll)
            }
        }
    }, [])

    const toggleChatBox = () => {
        setIsOpen(!isOpen)
    }

    const handleClose = () => {
        onClose(userData)
    }

    async function getChatMessages(isInitial = false) {
        if (pagination.hasMore && !isLoading) {
            setIsLoading(true)

            const tempPagination = { ...pagination }
            const chatReq: ChatMessagesRequest = {
                domainId: appDomainId,
                receiver: userData.id,
                sender: userId,
                page: pagination.pageNo,
                pageSize: pagination.pageSize,
                loadOnlyUnread: false,
            }

            const chatMessages = await messageService.getChatMessages(chatReq)
            if (chatMessages.length < pagination.pageSize) {
                tempPagination.hasMore = false
            } else {
                tempPagination.pageNo++
            }

            setIsLoading(false)
            setPagination(tempPagination)
            dispatch(messengerCreators.updateUserChatsList([...chatMessages.reverse(), ...messages], userData.id))
            if (isInitial) scrollToBottom(true)

            // setUnReadCount(2) // Add unread or New message
        }
    }

    const onSendMessage = message => {
        const processedData = extractDataFromHtml(message, userId)
        const messageRequest: Message = {
            id: createGuid(),
            sender: userId,
            receiver: userData.id,
            content: message,
            cloudId,
            modelPartId,
            appId,
            portalId: tModule.id,
            domainId: appDomainId,
            tags: processedData?.tags,
            mentions: processedData?.mentions,
        }

        messageService.sendMessage(messageRequest)
        dispatch(
            messengerCreators.pushMessageToUserChat(
                {
                    ...messageRequest,
                    createdAt: new Date().toLocaleString(),
                    senderId: userId,
                    receiverId: userData.id,
                },
                messageRequest.receiver
            )
        )

        scrollToBottom()

        const chatMessage = {
            senderId: userId,
            receiverId: userData.id,
            lastUnreadMessage: messageRequest,
        }

        let newChatList = chatList.filter(chat => !(chat.receiverId === userData.id && chat.senderId === userId))
        newChatList = [chatMessage, ...newChatList]
        dispatch(messengerCreators.setUserMessages(newChatList))
    }

    return (
        <Box
            className={classNames(classes.chatBoxContainer, {
                [classes.chatBoxOpen]: isOpen,
                'slide-in-bottom': openClass,
            })}
        >
            <Box className={classNames(classes.chatBoxToggle, classes.chatHeader)} onClick={toggleChatBox}>
                <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', ml: 0.5 }}>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        invisible={!isOnline}
                        color={'success'}
                    >
                        <Avatar className={classes.avatar}>{userData?.ScreenAlias[0].toUpperCase()}</Avatar>
                    </Badge>
                    <Typography variant="body1" sx={{ fontSize: '1.2rem', fontWeight: 500, marginRight: '10px' }}>
                        {userData?.ScreenAlias}
                    </Typography>
                    <Badge badgeContent={unReadCount} color="secondary"></Badge>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <IconButton
                        onClick={() => handleClose()}
                        className={classes.buttonStyle}
                        size="small"
                        data-cy="close-chat"
                    >
                        <CloseRounded />
                    </IconButton>
                </Box>
            </Box>
            <Box className={`${classes.chatBox} ${chatClasses.chatContent} ${isOpen ? classes.chatBoxOpen : ''}`}>
                <div ref={chatRef} className={chatClasses?.messageList}>
                    {isLoading && (
                        <Box my={1}>
                            <Spinner />
                        </Box>
                    )}
                    {!isLoading && messages.length === 0 && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>No Messages found</Box>
                    )}
                    {messages?.map((message, i) => (
                        <Fragment key={i}>
                            {unReadCount !== 0 && i === messages.length - unReadCount && (
                                <Divider component="span" role="presentation" className={chatClasses?.chatUnread}>
                                    New Messages
                                </Divider>
                            )}
                            <MessageItem message={message} userId={userId} />
                        </Fragment>
                    ))}
                    {showScroll && (
                        <Fab
                            sx={{
                                position: 'absolute',
                                bottom: 65,
                                right: 20,
                                minHeight: 35,
                                height: 35,
                                width: 35,
                                fontSize: '20px',
                            }}
                            size="small"
                            onClick={() => scrollToBottom()}
                            aria-label="Scroll Bottom"
                        >
                            <Badge badgeContent={unReadCount} color="secondary">
                                <ArrowDownwardRounded />
                            </Badge>
                        </Fab>
                    )}
                </div>
                <SendMessage onSendMessage={onSendMessage} />
            </Box>
        </Box>
    )
}

const MessageItem = ({ message, userId }) => {
    const chatClasses = useStyles()
    const isSender = message?.senderId === userId

    return (
        <div
            className={classNames(chatClasses.chatBubble, {
                [chatClasses.chatBubbleRight]: isSender,
            })}
        >
            <div>
                <Typography
                    variant="caption"
                    className={classNames(chatClasses.chatDate, {
                        [chatClasses.chatDateRight]: isSender,
                    })}
                >
                    {moment(message.createdAt).format('MM/DD hh:mm a')}
                </Typography>
                <Markdown>{message.content}</Markdown>
            </div>
        </div>
    )
}

const SendMessage = ({ onSendMessage }) => {
    const chatClasses = useStyles()
    const [message, setMessage] = useState(null)

    const accessKey = useSelector(authSelectors.getAccessKey)
    const widgets = useSelector(widgetSelectors.getAllWidgets)
    const modelName = useSelector(applicationSelectors.getCurrentAppName)

    const getUserList = async (q: string) => {
        const usersFetchRequest: UsersRequest = {
            ModelName: modelName,
            PageSize: 10,
            PageNumber: 1,
            loaddetails: true,
            superUsersOnly: false,
            userIdSearch: q,
        }

        const usersRes = await userService.getUsersV2(usersFetchRequest)

        const userList = usersRes?.Users?.map(user => ({
            userId: user.UserInfo.UserId,
            userName: user.UserInfo.ScreenAlias,
        }))

        return userList || []
    }

    const getWidgetList = async (q: string) => {
        const widgetList = widgets.map(widget => {
            return {
                name: widget.Name,
                title: widget.Title,
                id: widget.Id,
            }
        })
        return widgetList || []
    }

    const onChange = event => {
        setMessage(event)
    }

    return (
        <Box className={chatClasses?.messageBox}>
            <Box width={'100%'}>
                <TextEditorV2
                    editable={true}
                    isChat={true}
                    minHeight={200}
                    initialValue={message}
                    userAccessKey={accessKey || null}
                    onChange={onChange}
                    getUserList={getUserList}
                    getWidgetList={getWidgetList}
                    onHandleSubmit={onSendMessage}
                />
            </Box>
        </Box>
    )
}

const extractDataFromHtml = (htmlString, user) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')

    const mentions = []
    const tags = []

    const spanElements = doc.querySelectorAll('span.mention')

    spanElements.forEach(span => {
        const dataType = span.getAttribute('data-type')
        const dataId = span.getAttribute('data-id')
        const dataLabel = span.getAttribute('data-label')

        if (dataType === 'userNames') {
            mentions.push({
                userId: dataId,
                userName: dataLabel,
            })
        } else if (dataType === 'widgetTags') {
            tags.push({
                id: dataId,
                name: dataLabel,
                createdAt: new Date().toISOString(),
                createdBy: user,
            })
        }
    })

    return {
        mentions,
        tags,
    }
}

const createGuid = () => {
    let d = new Date().getTime()
    const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
    return guid
}

export default ChatBox

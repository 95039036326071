export const chatTypes = {
    UPDATE_CHAT_MESSAGES: 'UPDATE_CHAT_MESSAGES',
    UPDATE_AI_CHAT_MESSAGES: 'UPDATE_AI_CHAT_MESSAGES',
    TOGGLE_SPLIT_SCREEN: 'TOGGLE_SPLIT_SCREEN',
    CLEAR_ALL_MESSAGES: 'CLEAR_ALL_MESSAGES',
    INITIATE_AI_CHAT_SESSION: 'INITIATE_AI_CHAT_SESSION',
    CLEAR_AI_CHAT_SESSION: 'CLEAR_AI_CHAT_SESSION',
    TOGGLE_CHAT_DENSITY: 'TOGGLE_CHAT_DENSITY',
    UPDATE_CHAT_SETTINGS: 'UPDATE_CHAT_SETTINGS',
    UPDATE_CHAT_VERSION: 'UPDATE_CHAT_VERSION',
    TOGGLE_SHOW_SUGGESTIONS: 'TOGGLE_SHOW_SUGGESTIONS',
}
